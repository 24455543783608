export const dashboardData = {
title:"Dashboard",
statTitle:"Statistiken",
kfzTitle:"KFZ-Aufträge",
kfz:"KFZ",
kfzRentTitle:"SHW-Aufträge",
kfzRent:"SHW",
heute:"Heute",
garageTitle:"Fahrzeuge",
customerTitle:"Kunden",
werkstattTitle:"Werkzeug",
todayTitle:"Heute fällig",
qkTitle:"Schnelle Aktionen",
qkBtn1:"KFZ-Auftrag hinzufügen",
qkBtn2:"SHW-Auftrag hinzufügen",
qkBtn3:"Fahrzeug hinzufügen",
qkBtn4:"Kunde hinzufügen",
qkBtn5:"Werkzeug hinzufügen",
noWork:"Heute keine fällige Arbeit",
};

  import {
    ColumnContainer,
    Panel,
    SafePanel,
    LimitedContainer,
    LabelXXL,
  } from "../components/Ui/Ui";
import { settingsData } from "../data/Settings";
  const Settings = () => {
    return (
        <Panel>
          <SafePanel normal={false} allowMargin={false}>
            <ColumnContainer>
              <LimitedContainer>
                <LabelXXL color="White"  fontWeight="900">
                  {settingsData.title}
                </LabelXXL>
              </LimitedContainer>
            </ColumnContainer>
          </SafePanel>
        </Panel>
    );
  };
  
  export default Settings;
import React from 'react';
import './SideBar.styles.js';
import { useNavigate } from "react-router-dom";
import { Nav, NavbarContainer, NavIcon, NavItem, NavLinks, NavLogo, NavMenu } from './SideBar.styles.js';
import { data} from '../../data/SideBar.js';
import { copyrightData, srcData } from '../../data/Src.js';
import { handleSignOut, NavToPage } from '../../utils/utilities.js';
import { Button, ColumnContainer, LabelXXS, RowContainer, Space } from '../Ui/Ui.js';

const SideBar = ({children}) => {
  let navigate = useNavigate();
  return (
    <Nav>
    <NavbarContainer style={{height:"inherit"}}>
      <NavLogo to="/dashboard">
        <NavIcon src={srcData.logo.localuri} alt="logo" />
      </NavLogo>
      <NavMenu>
        {data.map((el, index) => {
          if (!el.text) return null; // Skip rendering if el.text is empty or falsy
          return (
            <NavItem key={index}>
              <NavLinks onClick={() => NavToPage(navigate, el.to)}>
                {el.text}
              </NavLinks>
            </NavItem>
          );
        })}
      </NavMenu>
      <ColumnContainer style={{height:"inherit", flexDirection:"column-reverse"}}>
      <Space style={{height:"2rem"}}/>
      <ColumnContainer>
      <RowContainer
              style={{
                justifyContent: "center",
                marginTop: "2rem",
                alignContent: "center",
              }}
            >
              <ColumnContainer style={{ flexDirection: "column-reverse" }}>
                <LabelXXS
                  style={{ opacity: "0.1", margin: "0", color: "wheat" }}
                >
                  {copyrightData.name}
                </LabelXXS>
                <LabelXXS
                  style={{ opacity: "0.1", margin: "0", color: "wheat" }}
                >
                  {copyrightData.desciprion}
                </LabelXXS>
                <LabelXXS
                  style={{
                    fontWeight: "bolder",
                    opacity: "0.1",
                    margin: "0",
                    color: "wheat",
                  }}
                >
                  {copyrightData.title}
                </LabelXXS>
              </ColumnContainer>
            </RowContainer>
  </ColumnContainer>
  <ColumnContainer style={{padding:"1rem"}}>
      <Button style={{backgroundColor:"red"}} onClick={async ()=>{
        await handleSignOut()
          window.location.reload()
      }}>Abmelden</Button>
      </ColumnContainer>
  </ColumnContainer>
    </NavbarContainer>
  </Nav>

  );
};

export default SideBar;

// onClick={() => closeMobileMenu(el.to, el.id)}
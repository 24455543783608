import {
  ColumnContainer,
  Panel,
  SafePanel,
  Button,
  Space,
  LabelXXL,
  RowContainer,
  CircleButton,
  Overlay,
  StyledOverlayPanel,
  LabelXL,
  LabelL,
  StyledInput,
  RoundedContainer,
  ScrollContainerSize,
  Label,
  StyledSelect,
  customStyles,
} from "../components/Ui/Ui";
import {
  addNewRecord,
  clearData,
  deleteRecord,
  handleChangeUtil,
  isFormValid,
  queryRecords,
  subscribeToWorkshopData,
  updateRecord,
} from "../utils/utilities";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import { WorkshopItem } from "../models";
import { useEffect, useState } from "react";
import {
  inputDataMap,
  useFormDataWorkshop,
  useWorkshopDataStat,
  workshopAvailability,
  workshopMainData,
  workshopRentType,
} from "../data/Workshop";
import workshopItemsData from "../assets/workshop.json";
import { toast } from "react-toastify";
import { toastData } from "../data/Src";
import { useLocation } from "react-router-dom";
const Workshop = () => {
  const { formData, setFormData } = useFormDataWorkshop();
  const { workshopData, setWorkshopData } = useWorkshopDataStat();
  const [showOverlay, setShowOverlay] = useState(false);
  const [showEdit, setEdit] = useState(false);
  const [workshopTmp, setWorksopTmp] = useState(false);
  const location = useLocation();
  const handleSaveWorkshop = async () => {
    await addNewRecord(WorkshopItem, formData);
    setShowOverlay(false);
  };

  const handleDeleteWorkshop = async () => {
    await deleteRecord(WorkshopItem, workshopTmp.id, setWorkshopData);
  };

  const handleUpdateWorkshop = async () => {
    await updateRecord(WorkshopItem, workshopTmp.id, formData, setWorkshopData);

    setShowOverlay(false);
  };

  useEffect(() => {
    queryRecords(WorkshopItem, setWorkshopData);
    const subscription = subscribeToWorkshopData(setWorkshopData);
    if (location.state?.triggerButton) {
      document.getElementById("btnOverlay")?.click(); // Simulate button click
    }
    return () => {
      subscription.unsubscribe();
    };
  }, [setWorkshopData, location.state]);
  return (
    <Panel>
      <SafePanel normal={false} allowMargin={false}>
        <ColumnContainer style={{ width: "100%" }}>
          <RowContainer style={{ alignItems: "center" }}>
            <LabelXXL color="White" fontWeight="900">
              {workshopMainData.title}
            </LabelXXL>
            <Space style={{ width: "2rem" }} />
            <CircleButton
              id="btnOverlay"
              onClick={() => {
                setEdit(false);
                setFormData(clearData(formData));
                setShowOverlay(true);
              }}
            >
              <AddIcon sx={{ color: "black" }} />
            </CircleButton>
          </RowContainer>

          <ScrollContainerSize>
            {workshopData &&
            workshopData.length > 0 &&
            workshopData[0] !== "" ? (
              workshopData.map((el, index) => (
                <RoundedContainer
                  key={index}
                  onClick={() => {
                    setShowOverlay(true);
                    setEdit(true);
                    setFormData({
                      type: el.type,
                      name: el.name,
                      availability: el.availability,
                      rentalType: el.rentalType,
                      normalPrice: el.normalPrice,
                      weekendPrice: el.weekendPrice,
                      description: el.description,
                    });

                    setWorksopTmp(el);
                  }}
                >
                  <ColumnContainer>
                    <LabelL>{el.name}</LabelL>
                    <LabelL>
                      {workshopItemsData.find(
                        (option) => option.value === el.type
                      )?.label || "null"}
                    </LabelL>
                    <LabelL>
                      {workshopAvailability.find(
                        (option) => option.value === el.availability
                      )?.label || "null"}
                    </LabelL>
                    <RowContainer style={{ justifyContent: "space-between" }}>
                      <LabelL>
                        {workshopRentType.find(
                          (option) => option.value === el.rentalType
                        )?.label || "null"}
                      </LabelL>
                      <LabelL>Wochenpreis: {el.normalPrice}€</LabelL>
                      <LabelL>Wochenendpreis: {el.weekendPrice}€</LabelL>
                    </RowContainer>
                    <LabelL>{el.description}</LabelL>
                    <RowContainer
                      style={{ justifyContent: "flex-end", opacity: "0.3" }}
                    >
                      <Label>{el.customerNumber}</Label>
                    </RowContainer>
                  </ColumnContainer>
                </RoundedContainer>
              ))
            ) : (
              <LabelL color="white" fontWeight="600">
                {workshopMainData.nun}
              </LabelL>
            )}
          </ScrollContainerSize>
        </ColumnContainer>
        <Overlay show={showOverlay}>
          <StyledOverlayPanel>
            <RowContainer
              style={{ alignItems: "center", justifyContent: "space-between" }}
            >
              <LabelXL fontWeight="600">
                {showEdit
                  ? workshopMainData.overlayEditTitle
                  : workshopMainData.overlayTitle}
              </LabelXL>
              <RowContainer>
                <CircleButton
                  style={{ visibility: showEdit ? "visible" : "hidden" }}
                  onClick={() => {
                    handleDeleteWorkshop();
                    setShowOverlay(false);
                  }}
                >
                  <DeleteIcon sx={{ color: "black" }} />
                </CircleButton>
                <Space style={{ width: "1rem" }} />
                <CircleButton
                  onClick={() => {
                    setFormData(clearData(formData));
                    setShowOverlay(false);
                  }}
                >
                  <CloseIcon sx={{ color: "black" }} />
                </CircleButton>
              </RowContainer>
            </RowContainer>
            <Space style={{ height: "1rem" }} />
            <ColumnContainer>
              <ColumnContainer>
                <LabelL>{inputDataMap.type.labelName}</LabelL>
                <StyledSelect
                  styles={customStyles}
                  options={workshopItemsData}
                  value={
                    workshopItemsData.find(
                      (option) => option.value === formData.type
                    ) || null
                  }
                  placeholder={inputDataMap.type.inputTipp}
                  // isDisabled={!enabledInputs.type}
                  onChange={(selectedOption) => {
                    handleChangeUtil(
                      {
                        target: {
                          name: inputDataMap.type.inputName,
                          value: selectedOption?.value,
                        },
                      },
                      setFormData
                    );
                    //  handleInputChangeTest(inputDataMap.type.inputName,selectedOption?.value,setEnabledInputs,formData)}
                  }}
                />
              </ColumnContainer>
              <ColumnContainer>
                <LabelL>{inputDataMap.name.labelName}</LabelL>
                <StyledInput
                  type={inputDataMap.name.inputType}
                  name={inputDataMap.name.inputName}
                  value={formData[inputDataMap.name.inputName]}
                  onChange={(e) => {
                    handleChangeUtil(e, setFormData);
                    //  handleInputChangeTest(e.target.name,e.target.value,setEnabledInputs,formData)
                  }}
                  // disabled={!enabledInputs.name}
                />
              </ColumnContainer>
              <ColumnContainer>
                <LabelL>{inputDataMap.availability.labelName}</LabelL>
                <StyledSelect
                  styles={customStyles}
                  options={workshopAvailability}
                  value={
                    workshopAvailability.find(
                      (option) => option.value === formData.availability
                    ) || null
                  }
                  placeholder={inputDataMap.availability.inputTipp}
                  //isDisabled={!enabledInputs.availability}
                  onChange={(selectedOption) => {
                    handleChangeUtil(
                      {
                        target: {
                          name: inputDataMap.availability.inputName,
                          value: selectedOption?.value,
                        },
                      },
                      setFormData
                    );
                    //  handleInputChangeTest(inputDataMap.availability.inputName,selectedOption?.value,setEnabledInputs,formData)
                  }}
                />
              </ColumnContainer>
              <RowContainer style={{ justifyContent: "space-between" }}>
                <ColumnContainer>
                  <LabelL>{inputDataMap.rentalType.labelName}</LabelL>
                  <StyledSelect
                    styles={customStyles}
                    options={workshopRentType}
                    value={
                      workshopRentType.find(
                        (option) => option.value === formData.rentalType
                      ) || null
                    }
                    placeholder={inputDataMap.rentalType.inputTipp}
                    // isDisabled={!enabledInputs.rentalType}
                    onChange={(selectedOption) => {
                      handleChangeUtil(
                        {
                          target: {
                            name: inputDataMap.rentalType.inputName,
                            value: selectedOption?.value,
                          },
                        },
                        setFormData
                      );
                      //  handleInputChangeTest(inputDataMap.rentalType.inputName,selectedOption?.value,setEnabledInputs,formData);
                    }}
                  />
                </ColumnContainer>

                <ColumnContainer>
                  <LabelL>{inputDataMap.normalPrice.labelName}</LabelL>
                  <StyledInput
                    type={inputDataMap.normalPrice.inputType}
                    name={inputDataMap.normalPrice.inputName}
                    value={formData[inputDataMap.normalPrice.inputName]}
                    onChange={(e) => {
                      if(e.target.value!==""){
                        if (/^\d*\.?\d*$/.test(e.target.value)) {
                        setFormData((prev) => ({
                          ...prev,
                          normalPrice: parseInt(e.target.value, 10),
                        }));
                      }
                      } else{
                        setFormData((prev) => ({
                          ...prev,
                          normalPrice: "",
                        }));
                      }

                      //  handleInputChangeTest(e.target.name,e.target.value,setEnabledInputs,formData)
                    }}
                    //   disabled={!enabledInputs.normalPrice}
                  />
                </ColumnContainer>
                <ColumnContainer>
                  <LabelL>{inputDataMap.weekendPrice.labelName}</LabelL>
                  <StyledInput
                    type={inputDataMap.weekendPrice.inputType}
                    name={inputDataMap.weekendPrice.inputName}
                    value={formData[inputDataMap.weekendPrice.inputName]}
                    onChange={(e) => {
                      if(e.target.value!==""){
                        if (/^\d*\.?\d*$/.test(e.target.value)) {
                        setFormData((prev) => ({
                          ...prev,
                          weekendPrice: parseInt(e.target.value, 10),
                        }));
                      }
                      } else{
                        setFormData((prev) => ({
                          ...prev,
                          weekendPrice: "",
                        }));
                      }
             
                      //  handleInputChangeTest(e.target.name,e.target.value,setEnabledInputs,formData)
                    }}
                    //  disabled={!enabledInputs.weekendPrice}
                  />
                </ColumnContainer>
              </RowContainer>
              <ColumnContainer>
                <LabelL>{inputDataMap.description.labelName}</LabelL>
                <StyledInput
                  as="textarea" // Use the "as" prop to render a <textarea> instead of an <input>
                  rows={5} // Set the height of the text area
                  style={{
                    width: "100%", // Make it full width
                    resize: "vertical", // Allow resizing vertically
                    padding: "10px", // Add padding for better readability
                    fontSize: "1rem", // Adjust font size
                    lineHeight: "1.5", // Improve text spacing
                  }}
                  type={inputDataMap.description.inputType}
                  name={inputDataMap.description.inputName}
                  value={formData[inputDataMap.description.inputName]}
                  onChange={(e) => {
                    handleChangeUtil(e, setFormData);
                    //  handleInputChangeTest(e.target.name,e.target.value,setEnabledInputs,formData)
                  }}
                  placeholder="Enter a detailed description..." // Add a helpful placeholder
                />
              </ColumnContainer>
            </ColumnContainer>
            <Space style={{ height: "2rem" }} />
            <RowContainer style={{ width: "100%", justifyContent: "center" }}>
              <Button
                onClick={async () => {
                  if (isFormValid(formData)) {
                    if (showEdit) {
                      handleUpdateWorkshop();
                    } else {
                      handleSaveWorkshop();
                    }
                  } else {
                    toast.warning(toastData.formInvalid);
                  }
                }}
              >
                {workshopMainData.btnTitle}
              </Button>
            </RowContainer>
          </StyledOverlayPanel>
        </Overlay>
      </SafePanel>
    </Panel>
  );
};

export default Workshop;

import {
  ColumnContainer,
  Panel,
  SafePanel,
  LimitedContainer,
  LabelXXL,
  LabelL,
  LabelXL,
  RoundedContainer,
  RowContainer,
  Button,
} from "../components/Ui/Ui";
import {
  queryRecords,
  queryRecordsPromised,
  queryRentRecordsPromised,
  subscribeToOrdersData,
} from "../utils/utilities";
import { useNavigate } from "react-router-dom";
import { dashboardData } from "../data/Dashboard";
import { useEffect, useState } from "react";
import { useGarageDataStat } from "../data/Garage";
import { useCustomerDataStat } from "../data/Customers";
import { useOrdersDataStat } from "../data/Orders";
import { CarKFZ, CustomerKFZ, OrderKFZ, WorkshopReservation } from "../models";
import { Calendar, momentLocalizer } from "react-big-calendar";
import format from "date-fns/format";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import "moment/locale/de";
import { useRentDataStat } from "../data/Rent";
import { dataByKey } from "../data/SideBar";
import { toast } from "react-toastify";
import { toastData } from "../data/Src";
require("globalize/lib/cultures/globalize.culture.de");

moment.locale("de");
const localizer = momentLocalizer(moment);
const germanLang = {
  week: "Woche",
  work_week: "Arbeitswoche",
  day: "Tag",
  month: "Monat",
  previous: "Zurück",
  next: "Weiter",
  today: "Heute",
  agenda: "Agenda",
  showMore: (total) => `+${total} mehr`,
};

const Dashboard = () => {
  const { garageData, setGarageData } = useGarageDataStat();
  const { customerData, setCustomerData } = useCustomerDataStat();
  const { ordersData, setOrdersData } = useOrdersDataStat();
  const { rentData, setRentData } = useRentDataStat();
  const redirectToCustomerAndClick = () => {
    navigate(dataByKey.customers.to, { state: { triggerButton: true } });
  };
  const redirectToGarageAndClick = () => {
    navigate(dataByKey.garage.to, { state: { triggerButton: true } });
  };
  const redirectToWerkstattInventarAndClick = () => {
    navigate(dataByKey.inventory.to, { state: { triggerButton: true } });
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch data for Customers, Garage, Orders, and Rent
        await queryRecords(CustomerKFZ, setCustomerData);
        await queryRecords(CarKFZ, setGarageData);

        await queryRecordsPromised(OrderKFZ, setOrdersData).then(
          (orderData) => {
            const orderEvents = orderData.map((order) => ({
              title:
              dashboardData.kfz+" - " + order.customer.name + " - " + order.car.hersteller ||
              dashboardData.kfz,
              start: new Date(order.targetDate),
              end: new Date(order.targetDate), // Using targetDate for both start and end
            }));

            // Check for duplicates and set unique events
            setEvents((prevEvents) => {
              const allEvents = [...prevEvents, ...orderEvents];
              const uniqueEvents = allEvents.filter(
                (event, index, self) =>
                  index ===
                  self.findIndex(
                    (e) =>
                      e.start.getTime() === event.start.getTime() &&
                      e.title === event.title
                  )
              );
              return uniqueEvents;
            });
          }
        );

        await queryRentRecordsPromised(WorkshopReservation, setRentData).then(
          (rentData) => {
            // Process rentData into events
            const rentEvents = rentData.map((rent) => ({
              title: dashboardData.kfzRent+" - " + rent.customer.name || dashboardData.kfzRent, // Default title if not provided
              start: new Date(rent.reservationStart),
              end: new Date(rent.reservationEnd),
            }));

            // Check for duplicates and set unique events
            setEvents((prevEvents) => {
              const allEvents = [...prevEvents, ...rentEvents];
              const uniqueEvents = allEvents.filter(
                (event, index, self) =>
                  index ===
                  self.findIndex(
                    (e) =>
                      e.start.getTime() === event.start.getTime() &&
                      e.title === event.title
                  )
              );
              return uniqueEvents;
            });
          }
        );



        // Subscribe to updates for ordersData
        const subscription = subscribeToOrdersData(setOrdersData);
 

        // Cleanup function for subscription
        return () => {
          subscription.unsubscribe();
 
        };
      } catch (error) {
           toast.error(toastData.actionError+" "+toastData.dashboardError)
      }
    };

    fetchData();
  }, [setCustomerData,setGarageData,setOrdersData,setRentData]);
  const navigate = useNavigate();
  const [events, setEvents] = useState([]);
  return (
    <Panel>
      <SafePanel normal={false} allowMargin={false}>
        <ColumnContainer style={{ width: "100%" }}>
          <LimitedContainer>
            <LabelXXL color="white" fontWeight="900">
              {dashboardData.title}
            </LabelXXL>
          </LimitedContainer>
          <RowContainer
            style={{
              gap: "1rem",
              alignContent: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <ColumnContainer
              style={{ flex: "0.3", height: "inherit", gap: "1rem" }}
            >
              <RoundedContainer>
                <ColumnContainer>
                  <LabelXL>{dashboardData.statTitle}</LabelXL>
                  <LabelL>
                    {dashboardData.kfzTitle} {ordersData.length}
                  </LabelL>
                  <LabelL>
                    {dashboardData.kfzRentTitle} {rentData.length}
                  </LabelL>
                  <LabelL>
                    {dashboardData.garageTitle} {garageData.length}
                  </LabelL>
                  <LabelL>
                    {dashboardData.customerTitle} {customerData.length}
                  </LabelL>
                  {/* <LabelL>Anzahl Rechnungen</LabelL>
              <LabelL>Anzahl Berichte</LabelL> */}
                </ColumnContainer>
              </RoundedContainer>
              <RoundedContainer>
                <ColumnContainer>
                  <LabelXL>{dashboardData.kfzTitle} {dashboardData.heute}</LabelXL>
                  <LabelL>
                    {(() => {
                      // Check if ordersData is valid
                      if (
                        !Array.isArray(ordersData) ||
                        ordersData.length === 0
                      ) {
                        return "Heute fällige Arbeit: Keine";
                      }

                      // Get today's date in YYYY-MM-DD format
                      const today = new Date().toISOString().split("T")[0];

                      // Generate the label content
                      const labelContent = ordersData.reduce((acc, order) => {
                        if (!order || !order.targetDate || !order.car) {
                          return acc; // Skip invalid orders
                        }

                        const targetDate = order.targetDate.split("T")[0]; // Extract date part from AWS format
                        if (targetDate === today) {
                          const licensePlate =
                            order.car.kfz_kennzeichen || "KEIN KENNZEICHEN";
                          const manufacturer =
                            order.car.hersteller || "KEIN HERSTELLER"; // Default if missing
                          acc.push(
                            `${order.customer.name} (${manufacturer}, ${licensePlate})`
                          );
                        }
                        return acc;
                      }, []);

                      if (labelContent.length > 0) {
                        return `${labelContent.join(", ")}`;
                      } else {
                        return "Keine fällige Arbeit";
                      }
                    })()}
                  </LabelL>
                </ColumnContainer>
              </RoundedContainer>
              <RoundedContainer>
                <ColumnContainer>
                  <LabelXL>{dashboardData.kfzRentTitle}  {dashboardData.heute}</LabelXL>
                  <LabelL>
                    {(() => {
                      // Check if ordersData is valid
                      if (!Array.isArray(rentData) || rentData.length === 0) {
                        return dashboardData.noWork;
                      }

                      // Get today's date in YYYY-MM-DD format
                      const today = new Date().toISOString().split("T")[0];

                      // Generate the label content
                      const labelContent = rentData.reduce((acc, rent) => {
                        if (!rent || !rent.reservationStart) {
                          return acc; // Skip invalid orders
                        }

                        const targetDate = rent.reservationStart.split("T")[0]; // Extract date part from AWS format
                        if (targetDate === today) {
                          acc.push(`${rent.customer.name}`);
                        }
                        return acc;
                      }, []);

                      if (labelContent.length > 0) {
                        return `${labelContent.join(", ")}`;
                      } else {
                        return dashboardData.noWork;
                      }
                    })()}
                  </LabelL>
                </ColumnContainer>
              </RoundedContainer>

              <RoundedContainer>
                <ColumnContainer>
                  <LabelXL>{dashboardData.qkTitle}</LabelXL>

                  <ColumnContainer style={{ gap: "1rem" }}>

                      {/* <Button>{dashboardData.qkBtn1}</Button>
                      <Button>{dashboardData.qkBtn2}</Button> */}
                      <Button onClick={redirectToGarageAndClick}>{dashboardData.qkBtn3}</Button>
                      <Button onClick={redirectToCustomerAndClick}>{dashboardData.qkBtn4}</Button>
                      <Button onClick={redirectToWerkstattInventarAndClick}>{dashboardData.qkBtn5}</Button>
         
                  </ColumnContainer>
                </ColumnContainer>
              </RoundedContainer>
            </ColumnContainer>
            <ColumnContainer style={{ flex: "0.7", height: "inherit" }}>
              <RoundedContainer
                style={{ height: "inherit", backgroundColor: "gray" }}
              >
                <Calendar
                  localizer={localizer}
                  culture="de"
                  events={events}
                  startAccessor="start"
                  endAccessor="end"
                  messages={germanLang}
                  style={{
                    height: "90%",
                    width: "100%", // Adjust width as necessary
                    color: "white", // Add margin if needed
                  }}
                  views={["month"]}
                  defaultView="month"
                  formats={{
                    dayFormat: (date) => format(date, "dd.MM.yyyy"), // Format for days
                  }}
                />
              </RoundedContainer>
            </ColumnContainer>
          </RowContainer>
        </ColumnContainer>
      </SafePanel>
    </Panel>
  );
};

export default Dashboard;


  import {
    ColumnContainer,
    Panel,
    SafePanel,
    Space,
    LabelXXL,
    RowContainer,
    CircleButton,
  } from "../components/Ui/Ui";
import AddIcon from '@mui/icons-material/Add';
import { reportsData } from "../data/Reports";
  const Reports = () => {
    return (
        <Panel>
          <SafePanel normal={false} allowMargin={false}>
            <ColumnContainer>
            <RowContainer style={{alignItems:"center"}}>
                <LabelXXL color="White" fontWeight="900">
                {reportsData.title}
                </LabelXXL>
                <Space style={{width:"2rem"}}/>
                <CircleButton><AddIcon sx={{ color:"black"}}/></CircleButton>
                </RowContainer>
            </ColumnContainer>
          </SafePanel>
        </Panel>
    );
  };
  
  export default Reports;
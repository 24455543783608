import { useState } from "react";
export const workshopMainData = {
  title: "Werkstattsinventar",
  overlayTitle: "Werkzeug Hinzufügen",
  overlayEditTitle: "Werkzeug Bearbeiten",
  btnTitle: "Speichern",
  nun: "Keine Werkzeuge gespeichert!",
};

export const RENTAL_TYPES = {
  HOURLY: "HOURLY",
  DAILY: "DAILY",
};
export const useFormDataWorkshop = () => {
  const [formData, setFormData] = useState({
    type: "",
    name: "",
    availability: false,
    rentalType: RENTAL_TYPES.HOURLY, // Default rent type: "hour" or "day"
    normalPrice: "", // Default value for weekday rent price
    weekendPrice: "", // Default value for weekend rent price
    description: "",
  });

  return { formData, setFormData };
};
export const useEnableDataWorkshop = () => {
  const [enabledInputs, setEnabledInputs] = useState({
    type: true, // Start with the first input enabled
    name: false,
    availability: false,
    rentalType: false,
    normalPrice: false,
    weekendPrice: false,
    description: false,
  });
  return { enabledInputs, setEnabledInputs };
};
export const inputDataMap = {
  type: {
    labelName: "Typ",
    inputName: "type",
    inputType: "text",
    inputOption: "drop",
     inputTipp:"Typ auswählen"
  },
  name: {
    labelName: "Marke",
    inputName: "name",
    inputType: "text",
    inputOption: "text",
  },
  availability: {
    labelName: "Verfügbarkeit",
    inputName: "availability",
    inputType: "text",
    inputOption: "drop",
        inputTipp:"Verfügbarkeit auswählen"
  },
  normalPrice: {
    labelName: "Wochen Preis",
    inputName: "normalPrice",
    inputType: "text",
    inputOption: "text",
  },
  weekendPrice: {
    labelName: "Wochenend Preis",
    inputName: "weekendPrice",
    inputType: "text",
    inputOption: "text",
  },
  rentalType: {
    labelName: "Mietart",
    inputName: "rentalType",
    inputType: "text",
    inputOption: "drop",
    inputTipp:"Mietart auswählen"
  },
  description: {
    labelName: "Beschreibung",
    inputName: "description",
    inputType: "text",
    inputOption: "text",
  },
};


export const useWorkshopDataStat = () => {
  const [workshopData, setWorkshopData] = useState([""]);
  return { workshopData, setWorkshopData };
};

export const workshopAvailability = [
  { value: true, label: "Verfügbar" },
  { value: false, label: "Außer betrieb" },
]

export const workshopRentType = [
  { value: "HOURLY", label: "pro Stunde" },
  { value: "DAILY", label: "pro Tag" },
]

  import {
    ColumnContainer,
    Panel,
    SafePanel,
    LimitedContainer,
    LabelXXL,
  } from "../components/Ui/Ui";
import { notfData } from "../data/Notifications";
  const Notifications = () => {
    return (
        <Panel>
          <SafePanel normal={false} allowMargin={false}>
            <ColumnContainer>
              <LimitedContainer>
                <LabelXXL color="White" fontWeight="900">
                  {notfData.title}
                </LabelXXL>
              </LimitedContainer>
            </ColumnContainer>
          </SafePanel>
        </Panel>
    );
  };
  
  export default Notifications;
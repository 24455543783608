import { useState } from "react";

export const ordersDataMain = {
  title: "Aufträge",
  overlayTitle: "Auftrag Hinzufügen",
  overlayEditTitle: "Auftrag Bearbeiten",
  btnTitle: "Speichern",
  nun: "Keine Aufträge gespeichert!",
};

export const useFormDataOrders = () => {
   const initialFormData = {
    customer: "",
    car: "",
    targetDate:"",
    repairs: [],
  };

  const [formData, setFormData] = useState(initialFormData);

  const resetFormData = () => setFormData(initialFormData);

  return { formData, setFormData, resetFormData };
};

export const inputData = {
  kunde: {
    labelName: "Kunde",
    placeholder:"Kunde suchen...",
    inputName: "kunde",
    inputType: "object",
    input: "drop",
  },
  fahrzeug: {
    labelName: "Fahrzeug",
    placeholder:"Fahrzeug auswählen...",
    inputName: "fahrzeug",
    inputType: "object",
    input: "drop",
  },
  repairs: {
    labelName: "Reperatur",
    placeholder:"Reparatur auswählen...",
    inputName: "repairs",
    inputType: "array",
    input: "bigfield",
  },
  targetdate: {
    labelName: "Datum",
    placeholder:"Zieldatum auswählen...",
    inputName: "targetDate",
    inputType: "text",
  },
};

export const ordersRepairData = [
  {
    Motor: [
      "Ölwechsel",
      "Ölwechsel mit Ölfilter",
      "Ölwechsel mit Öl- und Luftfilter",
      "Zündkerzen wechseln",
      "Ventile einstellen",
      "Motorreinigung",
      "Steuerkette prüfen/ersetzen",
      "Keilriemen prüfen/ersetzen",
      "Turbo überprüfen",
      "Turbo wechseln",
      "Schläuche prüfen",
      "Schläuche wechseln",
    ],
  },
  {
    Getriebe: [
      "Getriebeöl wechseln",
      "Schaltgestänge prüfen/justieren",
      "Kupplung wechseln",
      "Automatikgetriebe spülen",
      "Schläuche prüfen",
      "Schläuche wechseln",
    ],
  },
  {
    Bremsen: [
      "Bremsen wechseln (Vorne und Hinten)",
      "Bremsen wechseln (Vorne)",
      "Bremsen wechseln (Hinten)",
      "Bremssattel prüfen/ersetzen",
      "Bremsbeläge wechseln (Vorne und Hinten)",
      "Bremsflüssigkeit wechseln",
      "Bremsscheiben prüfen/ersetzen",
      "Schläuche prüfen",
      "Schläuche wechseln",
    ],
  },
  {
    Reifen: [
      "Reifen wechseln (Sommer/Winter)",
      "Reifen auswuchten",
      "Reifen reparieren (z. B. bei einem Nagel im Reifen)",
      "Reifen auf Schäden prüfen",
      "Reifendruck überprüfen/einstellen",
      "Radlager wechseln",
    ],
  },
  {
    Scheiben: [
      "Scheibenwischer wechseln",
      "Frontscheibe reparieren (Steinschlag)",
      "Frontscheibe austauschen",
      "Heckscheibe reparieren/austauschen",
      "Seitenscheiben reparieren/austauschen",
      "Scheibenwasser auffüllen (mit Frostschutz)",
    ],
  },
  {
    Karosserie: [
      "Rost entfernen",
      "Unterbodenschutz auftragen",
      "Karosserie ausbeulen",
      "Lackarbeiten durchführen",
    ],
  },
  {
    Batterie: [
      "Batterie prüfen",
      "Batterie laden",
      "Batterie wechseln",
      "Batterieanschlüsse reinigen/prüfen",
    ],
  },
  {
    Fahrzeugdiagnose: [
      "Fehlerspeicher auslesen",
      "Fehlerspeicher löschen",
      "Sensoren prüfen (z. B. Luftmassenmesser, Lambda-Sonde)",
      "Diagnose für Motorprobleme",
      "Diagnose für Elektronikprobleme",
    ],
  },
  {
    Kühlmittel: [
      "Kühlmittel prüfen",
      "Kühlmittel auffüllen",
      "Kühlmittel wechseln",
      "Kühlsystem auf Lecks prüfen",
      "Thermostat prüfen/ersetzen",
    ],
  },
  {
    Filter: [
      "Luftfilter wechseln",
      "Innenraumfilter wechseln",
      "Kraftstofffilter wechseln",
      "Getriebefilter wechseln",
    ],
  },
  {
    Elektrik: [
      "Scheinwerferlampe wechseln",
      "Rücklicht reparieren",
      "Blinker prüfen/ersetzen",
      "Starter prüfen/ersetzen",
      "Lichtmaschine prüfen/ersetzen",
    ],
  },
  {
    Interieur: [
      "Sitze reinigen",
      "Sitzbezüge reparieren/ersetzen",
      "Armaturenbrett reparieren",
      "Fußmatten austauschen",
      "Innenraumbeleuchtung wechseln",
    ],
  },
  {
    Türen: [
      "Türverkleidung reparieren",
      "Türschlösser schmieren/reparieren",
      "Fensterheber prüfen/ersetzen",
      "Dichtungen prüfen/ersetzen",
    ],
  },
  {
    Abgasanlage: [
      "Auspuff prüfen/ersetzen",
      "Katalysator prüfen/ersetzen",
      "Partikelfilter reinigen/ersetzen",
      "Abgasanlage abdichten",
    ],
  },
  {
    Fahrwerk: [
      "Stoßdämpfer prüfen/ersetzen",
      "Achseinstellung prüfen",
      "Spurstangen prüfen/ersetzen",
      "Querlenker prüfen/ersetzen",
    ],
  },
  {
    Inspektion: [
      "Kleine Inspektion",
      "Große Inspektion",
      "Auf TÜV vorbereiten",
    ],
  },
  {
    Untersuchung: [
      "Auto springt nicht an",
      "Innenraum wird nicht warm",
      "Motor wird zu heiß",
      "Auto wackelt stark",
      "Auto lenkt nicht korrekt",
      "Auto startet, geht aber sofort aus",
      "Motor läuft unruhig",
      "Klimaanlage kühlt nicht",
      "Licht funktioniert nicht",
      "Bremsen quietschen",
      "Getriebe schaltet nicht richtig",
      "Lenkrad vibriert",
      "Auto zieht nach links/rechts",
      "Abgase riechen ungewöhnlich",
      "Batterie wird schnell leer",
      "Fahrzeug verliert Öl",
      "Kraftstoffverbrauch erhöht",
      "Kupplung rutscht durch",
      "Fensterheber funktioniert nicht",
      "Tür lässt sich nicht öffnen",
      "Zündschlüssel klemmt",
      "Warnleuchte für Motor leuchtet",
      "Reifen verliert Luft",
      "Wischerblätter reinigen nicht",
      "Fahrzeug setzt auf bei Bodenwellen",
      "Motorkontrollleuchte ist an",
      "ABS-Kontrollleuchte ist an",
      "Öldruck-Kontrollleuchte ist an",
      "Airbag-Kontrollleuchte ist an",
      "Batterie-Kontrollleuchte ist an",
      "Reifendruck-Warnleuchte ist an",
      "Kühlmitteltemperatur-Warnleuchte ist an",
      "ESP/ASR-Kontrollleuchte ist an",
      "Glühwendel-Symbol (Diesel-Vorwärmung) leuchtet",
      "Partikelfilter-Warnleuchte ist an",
      "Lenkrad-Kontrollleuchte ist an",
      "Bremsverschleiß-Warnleuchte ist an",
      "Getriebe-Warnleuchte ist an",
    ],
  },
];

export const useOrdersDataStat = () => {
  const [ordersData, setOrdersData] = useState([""]);
  return { ordersData, setOrdersData };
};
export const flattenOrdersRepairData = () => {
  return ordersRepairData.flatMap((category) =>
    Object.entries(category).flatMap(([key, tasks]) =>
      tasks.map((task) => ({
        category: key,
        task,
      }))
    )
  );
};

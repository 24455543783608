import { AsyncCollection, DataStore } from "aws-amplify/datastore";
import {
  CarKFZ,
  CustomerKFZ,
  OrderKFZ,
  WorkshopItem,
  WorkshopReservation,
} from "../models"; // Import your model
import React from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import {
  Document,
  Page,
  Text,
  View,
  pdf,
} from "@react-pdf/renderer";
import { signIn, signOut, getCurrentUser } from "aws-amplify/auth";
import { toast } from "react-toastify";
import { toastData } from "../data/Src";

export const NavToPage = (navigate, direction) => {
  navigate(direction);
};

export const clearData = (data) => {
  const clearedData = {};
  for (const key in data) {
    clearedData[key] = typeof data[key] === "number" ? "" : "";
  }
  return clearedData;
};

export const handleChangeUtil = (e, setFormData) => {
  const { name, value, type } = e.target;

  setFormData((prev) => ({
    ...prev,
    [name]: type === "number" ? parseInt(value, 10) || 0 : value,
  }));
};

export const randomize = () => {
  const randomNumbers = Math.floor(Math.random() * 10000000); // Generate a random number with up to 7 digits
  const numberString = randomNumbers.toString().padStart(7, "0"); // Ensure it has 7 digits
  const chars = ["L", "T", "M"];
  const positions = [];
  while (positions.length < 3) {
    const randomIndex = Math.floor(Math.random() * (numberString.length + 1));
    if (!positions.includes(randomIndex)) {
      positions.push(randomIndex);
    }
  }
  positions.sort((a, b) => a - b);
  let result = numberString.split("");
  for (let i = 0; i < positions.length; i++) {
    result.splice(positions[i], 0, chars[i]);
  }
  return result.join(""); // Join the array back into a string
};

////////AWS/////////
export const addNewRecord = async (model, data) => {
  try {
    const item = await DataStore.save(new model(data));

    toast.success(toastData.dataSaved);
    return item;
  } catch (error) {
    toast.error(toastData.actionError);

  }
};

export const queryRecords = async (model, setItems) => {
  try {
    const items = await DataStore.query(model);
    setItems(items);
  } catch (error) {
    console.error("Error fetching records:", error);
  }
};

export const queryRecordsPromised = async (model, setItems) => {
  try {
    // Fetch the data from DataStore
    const items = await DataStore.query(model);

    // Resolve any promises in the fetched items
    const resolvedData = await Promise.all(
      items.map(async (order) => {
        // Resolve promises in customer and car data if they exist
        const resolvedOrder = { ...order };
        if (order.customer && order.customer instanceof Promise) {
          resolvedOrder.customer = await order.customer;
        }
        if (order.car && order.car instanceof Promise) {
          resolvedOrder.car = await order.car;
        }
        // Optionally, resolve promises in repairs or other fields as needed
        if (order.repairs && Array.isArray(order.repairs)) {
          resolvedOrder.repairs = await Promise.all(
            order.repairs.map(async (repair) =>
              repair instanceof Promise ? await repair : repair
            )
          );
        }
        return resolvedOrder;
      })
    );



    // Set the resolved items in the state
    setItems(resolvedData);

    // Return the resolved data for further use
    return resolvedData;
  } catch (error) {
    console.error("Error fetching records:", error);
    throw error; // Rethrow error for caller to handle
  }
};

export const queryGarageRecordsPromised = async (model, setItems) => {
  try {
    // Fetch the data from DataStore
    const items = await DataStore.query(model);

    // Resolve any promises in the fetched items
    const resolvedData = await Promise.all(
      items.map(async (garage) => {
        // Resolve promises in customer and car data if they exist
        const resolvedOrder = { ...garage };
        if (garage.customer && garage.customer instanceof Promise) {
          resolvedOrder.customer = await garage.customer;
        }
        return resolvedOrder;
      })
    );


    // Set the resolved items in the state
    setItems(resolvedData);

    // Return the resolved data for further use
    return resolvedData;
  } catch (error) {
    console.error("Error fetching records:", error);
    throw error; // Rethrow error for caller to handle
  }
};
export const queryRentRecordsPromised = async (model, setItems) => {
  try {
    // Fetch the data from DataStore
    const items = await DataStore.query(model);

    // Resolve any promises in the fetched items
    const resolvedData = await Promise.all(
      items.map(async (rent) => {
        const resolvedRent = { ...rent };

        // Resolve the customer promise if it exists
        if (rent.customer && rent.customer instanceof Promise) {
          resolvedRent.customer = await rent.customer;
        }

        // Resolve the items inside the AsyncCollection (which may contain promises)
        if (rent.items && rent.items instanceof AsyncCollection) {
          const itemsArray = await rent.items.toArray(); // Convert AsyncCollection to an array
          resolvedRent.items = await Promise.all(
            itemsArray.map(async (itemReservation) => {
              const resolvedItemReservation = { ...itemReservation };

              // Resolve the workshopItem promise inside itemReservation
              if (
                itemReservation.workshopItem &&
                itemReservation.workshopItem instanceof Promise
              ) {
                resolvedItemReservation.workshopItem =
                  await itemReservation.workshopItem;
              }
              return resolvedItemReservation;
            })
          );
        } else if (Array.isArray(rent.items)) {
          // If items is already an array, just resolve the promises inside it
          resolvedRent.items = await Promise.all(
            rent.items.map(async (itemReservation) => {
              const resolvedItemReservation = { ...itemReservation };

              // Resolve the workshopItem promise inside itemReservation
              if (
                itemReservation.workshopItem &&
                itemReservation.workshopItem instanceof Promise
              ) {
                resolvedItemReservation.workshopItem =
                  await itemReservation.workshopItem;
              }

              return resolvedItemReservation;
            })
          );
        } else {
          resolvedRent.items = []; // Handle if items is undefined or not in expected form
        }

        return resolvedRent;
      })
    );

 

    // Set the resolved items in the state
    setItems(resolvedData);

    // Return the resolved data for further use
    return resolvedData;
  } catch (error) {
    console.error("Error fetching rent records:", error);
    throw error; // Rethrow error for caller to handle
  }
};
export const queryRecordsGaragePromised = async (model, setItems) => {
  try {
    const items = await DataStore.query(model);
    const resolvedData = await Promise.all(
      items.map(async (garage) => {
        const resolvedOrder = { ...garage };
        if (garage.customer && garage.customer instanceof Promise) {
          resolvedOrder.customer = await garage.customer;
        }
        if (garage.orders && garage.orders instanceof Promise) {
          resolvedOrder.orders = await garage.orders;
        }
        if (garage.repairs && Array.isArray(garage.repairs)) {
          resolvedOrder.repairs = await Promise.all(
            garage.repairs.map(async (repair) => {
              return repair instanceof Promise ? await repair : repair;
            })
          );
        }
        return resolvedOrder;
      })
    );
 
    setItems(resolvedData);
  } catch (error) {
    console.error("Error fetching records:", error);
  }
};

export const deleteRecord = async (model, id, setItems) => {
  confirmAlert({
    title: "Confirm Delete",
    message: "Are you sure you want to delete this record?",
    buttons: [
      {
        label: "Yes",
        onClick: async () => {
          try {
            await DataStore.delete(model, id);
            setItems((prevItems) => prevItems.filter((item) => item.id !== id)); // Remove from state
     
            toast.success(toastData.dataDeleted);
          } catch (error) {
            console.error("Error deleting record:", error);
            toast.error(toastData.actionError);
          }
        },
      },
      {
        label: "No",
       // onClick: () => console.log("Delete canceled."),
      },
    ],
  });
};

export const updateRecord = async (model, id, updatedFields, setItems) => {
  try {
    const original = await DataStore.query(model, id);

    if (!original) {
      console.error("Record not found for updating");
      toast.error(toastData.recordNotFound);
      return;
    }

    // Update the record in DataStore
    await DataStore.save(
      model.copyOf(original, (updated) => {
        Object.assign(updated, updatedFields); // Apply updated fields
      })
    );

    // Update the state
    setItems((prevItems) =>
      prevItems.map((item) =>
        item.id === id ? { ...item, ...updatedFields } : item
      )
    );


    toast.success(toastData.dataUpdated);
  } catch (error) {
    console.error("Error updating record:", error);
    toast.error(toastData.actionError);
  }
};

export const checkIfCustomerNumberExists = async (customerNumber) => {
  try {
    // Query the CustomerKFZ table by customerNumber
    const existingCustomer = await DataStore.query(CustomerKFZ, (c) =>
      c.customerNumber.eq(customerNumber)
    );
    if (existingCustomer.length === 0) {
      return false; // Number does not exist, you can proceed to add
    } else {
      return true; // Number exists, avoid adding
    }
  } catch (error) {
    console.error("Error checking customer number: ", error);
    return false; // Handle errors gracefully and allow adding
  }
};
export const checkIfCustomerExists = async (nummer) => {
  try {
    // Query the CustomerKFZ table by customerNumber
    const existingCustomer = await DataStore.query(CustomerKFZ, (c) =>
      c.nummer.eq(nummer)
    );
    if (existingCustomer.length === 0) {
      return false; // Number does not exist, you can proceed to add
    } else {
      return true; // Number exists, avoid adding
    }
  } catch (error) {
    console.error("Error checking customer number: ", error);
    return false; // Handle errors gracefully and allow adding
  }
};

export const subscribeToCustomerData = (setCustomerData) => {
  const subscription = DataStore.observe(CustomerKFZ).subscribe((msg) => {

    if (msg.opType === "INSERT" || msg.opType === "UPDATE") {
      setCustomerData((prevData) => {
        const updatedCustomer = msg.element;
        const index = prevData.findIndex(
          (customer) => customer.id === updatedCustomer.id
        );

        if (index === -1) {
          // Add new customer to the list
          return [...prevData, updatedCustomer];
        } else {
          // Update the existing customer in the list
          const updatedData = [...prevData];
          updatedData[index] = updatedCustomer;
          return updatedData;
        }
      });
    } else if (msg.opType === "DELETE") {
      setCustomerData((prevData) =>
        prevData.filter((customer) => customer.id !== msg.element.id)
      );
    }
  });

  // Return the subscription to allow unsubscribe later
  return subscription;
};

export const subscribeToWorkshopData = (setWorkshopData) => {
  const subscription = DataStore.observe(WorkshopItem).subscribe((msg) => {
   

    if (msg.opType === "INSERT" || msg.opType === "UPDATE") {
      setWorkshopData((prevData) => {
        const updatedWorkshop = msg.element;
        const index = prevData.findIndex(
          (workshop) => workshop.id === updatedWorkshop.id
        );

        if (index === -1) {
          // Add new customer to the list
          return [...prevData, updatedWorkshop];
        } else {
          // Update the existing customer in the list
          const updatedData = [...prevData];
          updatedData[index] = updatedWorkshop;
          return updatedData;
        }
      });
    } else if (msg.opType === "DELETE") {
      setWorkshopData((prevData) =>
        prevData.filter((workshop) => workshop.id !== msg.element.id)
      );
    }
  });

  // Return the subscription to allow unsubscribe later
  return subscription;
};

export const subscribeToGarageData = (setGarageData) => {
  const subscription = DataStore.observe(CarKFZ).subscribe((msg) => {
    console.log(msg.model, msg.opType, msg.element);

    if (msg.opType === "INSERT" || msg.opType === "UPDATE") {
      setGarageData((prevData) => {
        const updatedCar = msg.element;
        const index = prevData.findIndex((car) => car.id === updatedCar.id);

        if (index === -1) {
          // Add new customer to the list
          return [...prevData, updatedCar];
        } else {
          // Update the existing customer in the list
          const updatedData = [...prevData];
          updatedData[index] = updatedCar;
          return updatedData;
        }
      });
    } else if (msg.opType === "DELETE") {
      setGarageData((prevData) =>
        prevData.filter((car) => car.id !== msg.element.id)
      );
    }
  });

  // Return the subscription to allow unsubscribe later
  return subscription;
};

export const subscribeToRentData = (setRentData) => {
  const subscription = DataStore.observe(WorkshopReservation).subscribe(
    async (msg) => {


      // Helper function to resolve promises in the object
      const resolvePromisesInObject = async (rent) => {
        const resolvedRent = { ...rent };

        // Resolve customer promise if it exists
        if (rent.customer && rent.customer instanceof Promise) {
    
          resolvedRent.customer = await rent.customer;
      
        }

        // Resolve items if it's an AsyncCollection or array of promises
        if (rent.items && rent.items instanceof AsyncCollection) {
          const itemsArray = await rent.items.toArray(); // Convert AsyncCollection to an array
          resolvedRent.items = await Promise.all(
            itemsArray.map(async (itemReservation) => {
              const resolvedItemReservation = { ...itemReservation };

              // Resolve the workshopItem promise inside itemReservation
              if (
                itemReservation.workshopItem &&
                itemReservation.workshopItem instanceof Promise
              ) {
                resolvedItemReservation.workshopItem =
                  await itemReservation.workshopItem;
              }
              return resolvedItemReservation;
            })
          );
        } else if (Array.isArray(rent.items)) {
          // If items is already an array, just resolve the promises inside it
          resolvedRent.items = await Promise.all(
            rent.items.map(async (itemReservation) => {
              const resolvedItemReservation = { ...itemReservation };

              // Resolve the workshopItem promise inside itemReservation
              if (
                itemReservation.workshopItem &&
                itemReservation.workshopItem instanceof Promise
              ) {
                resolvedItemReservation.workshopItem =
                  await itemReservation.workshopItem;
              }

              return resolvedItemReservation;
            })
          );
        } else {
          resolvedRent.items = []; // Handle if items is undefined or not in expected form
        }

        return resolvedRent;
      };

      if (msg.opType === "INSERT" || msg.opType === "UPDATE") {
        const resolvedRent = await resolvePromisesInObject(msg.element); // Resolve promises for the new or updated order
     

        setRentData((prevData) => {
          const index = prevData.findIndex(
            (order) => order.id === resolvedRent.id
          );

          if (index === -1) {
            // Add new order
            return [...prevData, resolvedRent];
          } else {
            // Update existing order
            const updatedData = [...prevData];
            updatedData[index] = resolvedRent;
            return updatedData;
          }
        });
      } else if (msg.opType === "DELETE") {
        setRentData((prevData) =>
          prevData.filter((order) => order.id !== msg.element.id)
        );
      }
    }
  );

  // Return the subscription to allow unsubscribe later
  return subscription;
};

export const subscribeToOrdersData = (setOrdersData) => {
  const subscription = DataStore.observe(OrderKFZ).subscribe(async (msg) => {


    // Helper function to resolve promises in the object
    const resolvePromisesInObject = async (order) => {
      const resolvedOrder = { ...order };

      if (order.customer && order.customer instanceof Promise) {
        resolvedOrder.customer = await order.customer;
      }
      if (order.car && order.car instanceof Promise) {
        resolvedOrder.car = await order.car;
      }
      if (order.repairs && Array.isArray(order.repairs)) {
        resolvedOrder.repairs = await Promise.all(
          order.repairs.map(async (repair) =>
            repair instanceof Promise ? await repair : repair
          )
        );
      }

      return resolvedOrder;
    };

    if (msg.opType === "INSERT" || msg.opType === "UPDATE") {
      const resolvedOrder = await resolvePromisesInObject(msg.element); // Resolve promises for the new or updated order
      setOrdersData((prevData) => {
        const index = prevData.findIndex(
          (order) => order.id === resolvedOrder.id
        );

        if (index === -1) {
          // Add new order
          return [...prevData, resolvedOrder];
        } else {
          // Update existing order
          const updatedData = [...prevData];
          updatedData[index] = resolvedOrder;
          return updatedData;
        }
      });
    } else if (msg.opType === "DELETE") {
      setOrdersData((prevData) =>
        prevData.filter((order) => order.id !== msg.element.id)
      );
    }
  });

  // Return the subscription to allow unsubscribe later
  return subscription;
};

////////AWS/////////

//  ///
//  const resolvedData = await Promise.all(
//   items.map(async (order) => {
//     // Resolve promises in customer and car data if they exist
//     const resolvedOrder = { ...order };
//     if (order.customer && order.customer instanceof Promise) {
//       resolvedOrder.customer = await order.customer;
//     }
//     if (order.car && order.car instanceof Promise) {
//       resolvedOrder.car = await order.car;
//     }
//     // Optionally, resolve promises in repairs or other fields as needed
//     if (order.repairs && Array.isArray(order.repairs)) {
//       resolvedOrder.repairs = await Promise.all(
//         order.repairs.map(async (repair) => {
//           return repair instanceof Promise ? await repair : repair;
//         })
//       );
//     }
//     return resolvedOrder;
//   })
// );
// console.log(resolvedData);
// // Set the resolved items in the state
// setItems(resolvedData);
// ///

export const getCurrentDate = () => {
  const days = [
    "Sonntag",
    "Montag",
    "Dienstag",
    "Mittwoch",
    "Donnerstag",
    "Freitag",
    "Samstag",
  ];
  const today = new Date();
  const dayName = days[today.getDay()];
  const date = today.toLocaleDateString("de-DE"); // Format as DD.MM.YYYY
  return `${dayName} ${date}`;
};

/// PDF///
const styles = {
  page: {
    padding: "30px",
    fontFamily: "Helvetica",
    fontSize: "12px",
  },
  header: {
    marginBottom: "20px",
    textAlign: "center",
  },
  companyName: {
    fontSize: "16px",
    fontWeight: "bold",
  },
  orderTitle: {
    fontSize: "14px",
    marginTop: "5px",
  },
  section: {
    marginBottom: "20px",
  },
  sectionTitle: {
    fontSize: "14px",
    fontWeight: "bold",
    marginBottom: "10px",
    textTransform: "uppercase",
  },
  table: {
    borderWidth: 1,
    borderColor: "#000",
    borderStyle: "solid",
    marginBottom: "10px",
  },
  row: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#000",
    borderBottomStyle: "solid",
    padding: "2px 0",
  },
  cell: {
    flex: 1,
    padding: "0px 5px",
    flexDirection: "column",
  },
  cellLabel: {
    fontSize: "8px",
    color: "#666",
    textTransform: "uppercase",
  },
  cellContent: {
    fontSize: "12px",
    fontWeight: "bold",
  },
  tableHeader: {
    fontWeight: "bold",
    backgroundColor: "#f0f0f0",
    textTransform: "uppercase",
  },
  cellPos: {
    width: "10%",
    textAlign: "center",
  },
  cellDesc: {
    width: "90%",
    paddingLeft: "10px",
  },
  noRepairs: {
    textAlign: "center",
    fontStyle: "italic",
    marginTop: "10px",
  },
};

export const generatePDFAndPrint = async (ordersMgmtSelectedData) => {
  if (
    !ordersMgmtSelectedData?.models ||
    ordersMgmtSelectedData.models.length === 0
  ) {
    alert("No orders to print.");
    return;
  }

  try {
    // Generate the PDF blob
    const blob = await pdf(
      <Document>
        {ordersMgmtSelectedData.models.map((el, index) => (
          <Page size="A4" style={styles.page} key={index}>
            {/* Firmenname und Werkstattauftrag */}
            <View style={styles.header}>
              <Text style={styles.companyName}>LTM</Text>
              <Text style={styles.orderTitle}>
                Werkstattauftrag vom{" "}
                {new Date(el.targetDate).toLocaleDateString("de-DE", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                })}
              </Text>
            </View>

            {/* Tabelle: Daten vom Kunde und Auto */}
            <View style={styles.section}>
              {/* <Text style={styles.sectionTitle}>daten vom kunde und auto</Text> */}
              <View style={styles.table}>
                {/* Reihe 1 */}
                <View style={styles.row}>
                  <View style={styles.cell}>
                    <Text style={styles.cellLabel}>name</Text>
                    <Text style={styles.cellContent}>
                      {el.customer?.name || "unbekannt"}
                    </Text>
                  </View>
                  <View style={styles.cell}>
                    <Text style={styles.cellLabel}>nummer</Text>
                    <Text style={styles.cellContent}>
                      ****{el.customer?.nummer?.slice(-3) || "***"}
                    </Text>
                  </View>
                  <View style={styles.cell}>
                    <Text style={styles.cellLabel}>kundennummer</Text>
                    <Text style={styles.cellContent}>
                      ****{el.customer?.customerNumber?.slice(-3) || "***"}
                    </Text>
                  </View>
                </View>

                {/* Reihe 2 */}
                <View style={styles.row}>
                  <View style={styles.cell}>
                    <Text style={styles.cellLabel}>hersteller</Text>
                    <Text style={styles.cellContent}>
                      {el.car?.hersteller || "unbekannt"}
                    </Text>
                  </View>
                  <View style={styles.cell}>
                    <Text style={styles.cellLabel}>modell</Text>
                    <Text style={styles.cellContent}>
                      {el.car?.modell || "unbekannt"}
                    </Text>
                  </View>
                  <View style={styles.cell}>
                    <Text style={styles.cellLabel}>kennzeichen</Text>
                    <Text style={styles.cellContent}>
                      {el.car?.kfz_kennzeichen || "unbekannt"}
                    </Text>
                  </View>
                </View>

                {/* Reihe 3 */}
                <View style={styles.row}>
                  <View style={styles.cell}>
                    <Text style={styles.cellLabel}>kilometer</Text>
                    <Text style={styles.cellContent}>
                      {el.car?.kilometer?.toLocaleString() || "unbekannt"} km
                    </Text>
                  </View>
                  <View style={styles.cell}>
                    <Text style={styles.cellLabel}>baujahr</Text>
                    <Text style={styles.cellContent}>
                      {el.car?.baujahr || "unbekannt"}
                    </Text>
                  </View>
                </View>
              </View>
            </View>

            {/* Reparaturen */}
            <View style={styles.section}>
              {/* <Text style={styles.sectionTitle}>reparaturen</Text> */}
              {el.repairs?.length > 0 ? (
                <View style={styles.table}>
                  <View style={[styles.row, styles.tableHeader]}>
                    <Text style={styles.cellPos}>pos</Text>
                    <Text style={styles.cellDesc}>beschreibung</Text>
                  </View>
                  {el.repairs.map((repair, i) => (
                    <View style={styles.row} key={i}>
                      <Text style={styles.cellPos}>{i + 1}</Text>
                      <Text style={styles.cellDesc}>{repair}</Text>
                    </View>
                  ))}
                </View>
              ) : (
                <Text style={styles.noRepairs}>
                  keine reparaturen verfügbar
                </Text>
              )}
            </View>
          </Page>
        ))}
      </Document>
    ).toBlob();

    // Open the PDF in a new window
    const pdfURL = URL.createObjectURL(blob);
    const printWindow = window.open(pdfURL);

    if (!printWindow) {
      alert("Popup blocked. Please allow popups for this website to print.");
      return;
    }

    // Trigger the print dialog after the new window has loaded
    printWindow.onload = () => {
      printWindow.focus(); // Ensure the window is focused
      printWindow.print(); // Trigger the print dialog
      printWindow.onafterprint = () => {
        printWindow.close(); // Close the window after printing
      };
    };
  } catch (error) {
    console.error("Failed to generate and print the PDF:", error);
    alert("An error occurred while generating the PDF. Please try again.");
  }
};

////

///AUTH///

export async function handleSignOut() {
  toast.success(toastData.signOut);

  await new Promise((resolve) => setTimeout(resolve, 2000));
  await signOut({ global: true });
}

export async function handleSignIn(username, password) {
  try {
    await signIn({
      username: username,
      password: password,
    });
    toast.success(toastData.loginSuccess);
    await new Promise((resolve) => setTimeout(resolve, 2000));
    return true;
  } catch (e) {
    const str = e.message;
    if (str.includes("already a signed")) {
      return true;
    }
    toast.error(toastData.loginFailure);
    return false;
  }
}
export async function isSignedIn() {
  try {
    await getCurrentUser();
    return true;
  } catch (err) {
    return false;
  }
}

// export const handleInputChangeTest = (name , value, setEnabledInputs, formData) => {
//   if (value!== "") {
//     const inputOrder = Object.keys(formData);
//     const currentIndex = inputOrder.indexOf(name);
//     if (currentIndex < inputOrder.length - 1) {
//       const nextInput = inputOrder[currentIndex + 1];
//       setEnabledInputs((prev) => ({
//         ...prev,
//         [nextInput]: true,
//       }));
//     }
//   }
// };

export const calculateRentalDuration = (start, end) => {
  const startDate = new Date(start);
  const endDate = new Date(end);

  const diffInMinutes = (endDate - startDate) / (1000 * 60); // Convert milliseconds to minutes
  const hours = diffInMinutes / 60; // Total hours (including fractional part)

  // Calculate rounded hours
  const fullHours = Math.floor(hours); // Full hours
  const remainingMinutes = (hours - fullHours) * 60; // Convert fractional part to minutes
  let rentalDuration = remainingMinutes >= 15 ? fullHours + 1 : fullHours; // Round up if >= 15 mins

  // Ensure that if duration is less than 1 hour, we set it to 1 hour
  if (rentalDuration < 1) {
    rentalDuration = 1;
  }

  return rentalDuration;
};
export const preisRechner = (hours, formItemData, setData) => {
  // Function to check if a day is a weekend
  const isWeekend = (date) => {
    const day = date.getDay();
    return day === 0 || day === 6; // 0 = Sunday, 6 = Saturday
  };

  // Get today's date to determine if it's a weekend
  const today = new Date();
  const weekend = isWeekend(today);

  // Calculate the total price
  let totalPrice = 0;

  formItemData.items.forEach((item) => {
    const price = weekend ? item.weekendPrice : item.normalPrice;

    if (item.rentalType === "HOURLY") {
      // Calculate rounded hours based on minutes
      const fullHours = Math.floor(hours); // Full hours
      const remainingMinutes = (hours - fullHours) * 60; // Convert fractional part to minutes
      const roundedHours = remainingMinutes >= 15 ? fullHours + 1 : fullHours; // Round up if >= 15 mins

      // Accumulate the total price based on rounded hours for hourly items
      totalPrice += price * roundedHours;
    } else if (item.rentalType === "DAILY") {
      // Calculate days (rounding up if necessary)
      const days = Math.ceil(hours / 24); // Round up if it's more than 1 hour and 1 minute
      totalPrice += price * days; // Accumulate the total price based on days for daily items
    }
  });


  // Update the data with the calculated total price
  setData((prevData) => ({
    ...prevData,
    price: totalPrice,
  }));
};

export const formatDate = (date) =>
  new Date(date)
    .toLocaleString("de-DE", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    })
    .replace(",", " Uhr");

export const isFormValid = (formData) => {
  for (const key in formData) {
    const value = formData[key];

    // Check for empty values (null, undefined, empty strings)
    if (
      value === null ||
      value === undefined ||
      (typeof value === "string" && value.trim() === "")
    ) {
      return false; // Return false if any value is empty or invalid
    }

    // If the value is a boolean, it's considered valid as long as it's explicitly true or false
    if (typeof value === "boolean") {
      continue; // No need to check further for boolean, it's valid
    }
  }
  return true; // Return true if no invalid fields are found
};

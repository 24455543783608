import {
  ColumnContainer,
  Panel,
  SafePanel,
  Button,
  Space,
  LabelXXL,
  RowContainer,
  CircleButton,
  Overlay,
  StyledOverlayPanel,
  LabelXL,
  LabelL,
  StyledInput,
  RoundedContainer,
  ScrollContainerSize,
  Label,
} from "../components/Ui/Ui";
import {
  addNewRecord,
  checkIfCustomerExists,
  checkIfCustomerNumberExists,
  clearData,
  deleteRecord,
  handleChangeUtil,
  isFormValid,
  queryRecords,
  randomize,
  subscribeToCustomerData,
  updateRecord,
} from "../utils/utilities";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import { CustomerKFZ } from "../models";
import {
  customersData,
  inputDataArray,
  useCustomerDataStat,
  useFormDataCustomer,
} from "../data/Customers";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { toastData } from "../data/Src";
import { useLocation } from "react-router-dom";

const Customers = () => {
  const { formData, setFormData } = useFormDataCustomer();
  const [showOverlay, setShowOverlay] = useState(false);
  const { customerData, setCustomerData } = useCustomerDataStat();
  const [showEdit, setEdit] = useState(false);
  const [customerTmp, setCustomerTmp] = useState(false);
  const location = useLocation();

  const handleSaveCustomer = async () => {
    const nameBool = await !checkIfCustomerExists(formData.nummer);
   
    if (!nameBool) {
      do {
        formData.customerNumber = randomize();
      } while (await checkIfCustomerNumberExists(formData.customerNumber));
  
      await addNewRecord(CustomerKFZ, formData);
      setShowOverlay(false);
      setFormData(clearData(formData));
    } else {
      setShowOverlay(false);
      setFormData(clearData(formData));
    }
  };

  const handleDeleteCustomer = async () => {
    await deleteRecord(CustomerKFZ, customerTmp.id, setCustomerData);
  };

  const handleUpdateCustomer = async () => {
    await updateRecord(CustomerKFZ, customerTmp.id, formData, setCustomerData);
    setShowOverlay(false);
  };

  useEffect(() => {
    // Subscribe to customer data changes when the component mounts
    queryRecords(CustomerKFZ, setCustomerData);
    const subscription = subscribeToCustomerData(setCustomerData);

  
    // Check for triggerButton state and simulate button click
    if (location.state?.triggerButton) {
      document.getElementById("btnOverlay")?.click(); // Simulate button click
    }
  
    // Clean up subscription when the component unmounts
    return () => {
      subscription.unsubscribe();
    };
  }, [setCustomerData, location.state]);
  return (
    <Panel>
      <SafePanel normal={false} allowMargin={false}>
        <ColumnContainer style={{ width: "100%" }}>
          <RowContainer style={{ alignItems: "center" }}>
            <LabelXXL color="White" fontWeight="900">
              {customersData.title}
            </LabelXXL>
            <Space style={{ width: "2rem" }} />
            <CircleButton id="btnOverlay"
              onClick={() => {
                setEdit(false);
                setFormData(clearData(formData));
                setShowOverlay(true);
                queryRecords(CustomerKFZ, setCustomerData);
              }}
            >
              <AddIcon sx={{ color: "black" }} />
            </CircleButton>
          </RowContainer>

          <ScrollContainerSize>
            { customerData &&
              customerData.length > 0 &&
              customerData[0] !== "" ? (customerData.map((el, index) => {
              return (
                <RoundedContainer
                  key={index}
                  onClick={() => {
                    setShowOverlay(true);
                    setEdit(true);
                    setFormData({
                      name: el.name,
                      nummer: el.nummer,
                      customerNumber: el.customerNumber,
                    });
                    setCustomerTmp(el);
                  }}
                >
                  <ColumnContainer key={index}>
                    <LabelL>{el.name}</LabelL>
                    <LabelL>{el.nummer}</LabelL>
                    <RowContainer
                      style={{ justifyContent: "flex-end", opacity: "0.3" }}
                    >
                      {" "}
                      <Label>{el.customerNumber}</Label>
                    </RowContainer>
                  </ColumnContainer>
                </RoundedContainer>
              );
            })):(
                          <LabelL color="white" fontWeight="600">
                            {customersData.nun}
                          </LabelL>
                        )}
          </ScrollContainerSize>
        </ColumnContainer>
        <Overlay show={showOverlay}>
          <StyledOverlayPanel>
            <RowContainer
              style={{ alignItems: "center", justifyContent: "space-between" }}
            >
              <LabelXL fontWeight="600">
                {showEdit
                  ? customersData.overlayEditTitle
                  : customersData.overlayTitle}
              </LabelXL>
              <RowContainer>
                <CircleButton
                  style={{ visibility: showEdit ? "visible" : "hidden" }}
                  onClick={() => {
                    handleDeleteCustomer();
                    setShowOverlay(false);
                  }}
                >
                  <DeleteIcon sx={{ color: "black" }} />
                </CircleButton>
                <Space style={{ width: "1rem" }} />
                <CircleButton
                  onClick={() => {
                    setFormData(clearData(formData));
                    setShowOverlay(false);
                  }}
                >
                  <CloseIcon sx={{ color: "black" }} />
                </CircleButton>
              </RowContainer>
            </RowContainer>
            <Space style={{ height: "1rem" }} />
            <ColumnContainer>
              <ColumnContainer>
                    <LabelL>{inputDataArray.name.labelName}</LabelL>
                    <StyledInput
                      type={inputDataArray.name.inputType}
                      name={inputDataArray.name.inputName}
                      value={formData[inputDataArray.name.inputName]}
                      onChange={(e) => handleChangeUtil(e, setFormData)}
                    />
                  </ColumnContainer>
                  <ColumnContainer>
                    <LabelL>{inputDataArray.nummer.labelName}</LabelL>
                    <StyledInput
                      type={inputDataArray.nummer.inputType}
                      name={inputDataArray.nummer.inputName}
                      value={formData[inputDataArray.nummer.inputName]}
                      onChange={(e) => {
                        if (/^\d*\.?\d*$/.test(e.target.value)) {
                          handleChangeUtil(e, setFormData);
                      }
                        }}
                    />
                  </ColumnContainer>
            </ColumnContainer>
            <Space style={{ height: "2rem" }} />
            <RowContainer style={{ width: "100%", justifyContent: "center" }}>
              <Button
                onClick={async () => {
          
                  if(formData.customerNumber==="") formData.customerNumber=0;  
                  if (isFormValid(formData)) {
                    if (showEdit) {
                      handleUpdateCustomer();
                    } else {
                      handleSaveCustomer();
                    }
                  } else {
                    toast.warning(toastData.formInvalid);
                  }
                }}
              >
                {customersData.btnTitle}
              </Button>
            </RowContainer>
          </StyledOverlayPanel>
        </Overlay>
      </SafePanel>
    </Panel>
  );
};

export default Customers;

import {
  ColumnContainer,
  Panel,
  SafePanel,
  Button,
  Space,
  LabelXXL,
  RowContainer,
  CircleButton,
  Overlay,
  StyledOverlayPanel,
  LabelXL,
  LabelL,
  StyledInput,
  ScrollContainerSize,
  RoundedContainer,
  Label,
  StyledSelect,
  customStyles,
} from "../components/Ui/Ui";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import hsnData from "../assets/hsntsn.json";
import {
  garageDataMain,
  inputDataObject,
  useFormDataGarage,
  useGarageDataStat,
} from "../data/Garage";
import { useEffect, useState } from "react";
import {
  addNewRecord,
  clearData,
  deleteRecord,
  handleChangeUtil,
  isFormValid,
  queryRecords,
  queryRecordsGaragePromised,
  subscribeToGarageData,
  updateRecord,
} from "../utils/utilities";
import { useCustomerDataStat } from "../data/Customers";
import { CarKFZ, CustomerKFZ } from "../models";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import { toastData, UnknowenData } from "../data/Src";
import { useLocation } from "react-router-dom";
const Garage = () => {
  const { formData, setFormData } = useFormDataGarage();
  const [showOverlay, setShowOverlay] = useState(false);
  const { garageData, setGarageData } = useGarageDataStat();
  const { customerData, setCustomerData } = useCustomerDataStat();
  const [showEdit, setEdit] = useState(false);
  const [garageTmp, setGarageTmp] = useState(false);
  const location = useLocation();

  const [inputValue, setInputValue] = useState("");
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const handleSearch = () => {
    // Normalize the input to handle spaces and case
    const normalizedInput = inputValue.replace(/\s+/g, "").toUpperCase();
    // Filter dataset by matching normalized HSN + TSN
    const results = hsnData.filter(
      (el) => `${el.HSN}${el.TSN}`.toUpperCase() === normalizedInput // Match normalized value
    );

    // Transform results into options for the select dropdown
    setFilteredOptions(
      results.map((el) => ({
        value: el.HSN+" "+ el.TSN,
        label: (
          <div style={{ padding: "8px", cursor: "pointer" }}>
            {el.HSN} {el.TSN} - {el.Modell}
          </div>
        ),
      }))
    );
  };

  useEffect(() => {
    queryRecords(CustomerKFZ, setCustomerData);
    queryRecordsGaragePromised(CarKFZ, setGarageData);
    const subscription = subscribeToGarageData(setGarageData);

    if (location.state?.triggerButton) {
      document.getElementById("btnOverlay")?.click(); // Simulate button click
    }
    return () => {
      subscription.unsubscribe();

    };
  }, [setGarageData, location.state,setCustomerData]);

  const handleSaveCar = async () => {
    await addNewRecord(CarKFZ, formData);
    setShowOverlay(false);
    setFormData(clearData(formData));
    setInputValue("");
    setSelectedOption(null)
  };
  const handleUpdateCar = async () => {
    await updateRecord(CarKFZ, garageTmp.id, formData, setGarageData);
    setShowOverlay(false);
  };
  const handleDeleteCar = async () => {
    await deleteRecord(CarKFZ, garageTmp.id, setGarageData);
    setShowOverlay(false);
  };
  return (
    <Panel>
      <SafePanel normal={false} allowMargin={false}>
        <ColumnContainer style={{ width: "100%" }}>
          <RowContainer style={{ alignItems: "center" }}>
            <LabelXXL color="White" fontWeight="900">
              {garageDataMain.title}
            </LabelXXL>
            <Space style={{ width: "2rem" }} />
            <CircleButton
              id="btnOverlay"
              onClick={() => {
                setEdit(false);
                setShowOverlay(true);
            
              }}
            >
              <AddIcon sx={{ color: "black" }} />
            </CircleButton>
          </RowContainer>
          <ScrollContainerSize>
            { garageData &&
              garageData.length > 0 &&
              garageData[0] !== "" ?  (garageData.map((el, index) => {
              const customerName = el.customer?.name || UnknowenData.unknowenCustomer;
              return (
                <RoundedContainer
                  onClick={() => {
                    setEdit(true);
                    setShowOverlay(true);

                    setFormData({
                      hersteller: el.hersteller,
                      modell: el.modell,
                      baujahr: el.baujahr,
                      kfz_kennzeichen: el.kfz_kennzeichen,
                      code: el.code,
                      kilometer: el.kilometer,
                    });
                    setInputValue(el.code);
                    setGarageTmp(el);
                  }}
                >
                  <ColumnContainer key={index}>
                    <LabelL>{customerName}</LabelL>
                    <LabelL>{el.hersteller}</LabelL>
                    <LabelL>{el.modell}</LabelL>
                    <LabelL>{el.baujahr}</LabelL>
                    <LabelL>{el.kfz_kennzeichen}</LabelL>
                    <LabelL>{el.code}</LabelL>
                    <LabelL>{el.kilometer}</LabelL>
                    <RowContainer
                      style={{ justifyContent: "flex-end", opacity: "0.3" }}
                    >
                      {" "}
                      <LabelL>{el.nummer}</LabelL>
                    </RowContainer>
                  </ColumnContainer>
                </RoundedContainer>
              );
            })):(<LabelL color="white" fontWeight="600">
                            {garageDataMain.nun}
                          </LabelL>)}
          </ScrollContainerSize>
        </ColumnContainer>
        <Overlay show={showOverlay}>
          <StyledOverlayPanel>
            <RowContainer
              style={{ alignItems: "center", justifyContent: "space-between" }}
            >
              <LabelXL fontWeight="600">
                {showEdit
                  ? garageDataMain.overlayEditTitle
                  : garageDataMain.overlayTitle}
              </LabelXL>
              <RowContainer>
                <CircleButton
                  style={{ visibility: showEdit ? "visible" : "hidden" }}
                  onClick={() => {
                    handleDeleteCar();
                    setShowOverlay(false);
                  }}
                >
                  <DeleteIcon sx={{ color: "black" }} />
                </CircleButton>
                <Space style={{ width: "1rem" }} />
                <CircleButton
                  onClick={() => {
                    setFormData(clearData(formData));
                    setSelectedOption(null)
                    setInputValue("");
                    setShowOverlay(false);
                  }}
                >
                  <CloseIcon sx={{ color: "black" }} />
                </CircleButton>
              </RowContainer>
            </RowContainer>
            <Space style={{ height: "1rem" }} />
            <ColumnContainer>
              <LabelL>{inputDataObject.customer.labelName}</LabelL>
              <StyledSelect
                styles={customStyles}
                options={customerData.map((customer) => ({
                  value: customer.customerNumber, // Unique identifier
                  label: (
                    <ColumnContainer
                      style={{ padding: "8px", cursor: "pointer" }}
                    >
                      <Label>{customer.name}</Label>
                      <Label>{customer.nummer}</Label>
                      <Label>{customer.customerNumber}</Label>
                    </ColumnContainer>
                  ),
                }))}
                onChange={(selectedOption) => {
                  const selectedCustomer = customerData.find(
                    (customer) =>
                      customer.customerNumber === selectedOption.value
                  );
                  setFormData((prevFormData) => ({
                    ...prevFormData,
                    customer: selectedCustomer, // Update form with selected customer data
                  }));
                }}
                placeholder={inputDataObject.customer.inputTipp}
              />
              <LabelL>{inputDataObject.code.labelName}</LabelL>
              <RowContainer style={{justifyContent:"space-between"}}>
              <StyledInput
                  type="text"
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  placeholder={inputDataObject.code.placeholder}
                  style={{ width:"auto" }}
                />
                <Button onClick={handleSearch}>{garageDataMain.btnSearchTitle}</Button>
                <StyledSelect
                  styles={customStyles}
                  options={filteredOptions}
                  value={selectedOption} 
                  onChange={(selectedOption) => {
                    setSelectedOption(selectedOption);
                    const selectedCode = hsnData.find(
                      (code) => code.HSN+" "+code.TSN === selectedOption.value
                    );
                    setFormData((prevFormData) => ({
                      ...prevFormData,
                      hersteller:selectedCode.Hersteller,
                      modell:selectedCode.Modell,
                      code: selectedCode.HSN+" "+selectedCode.TSN, // Update form with selected customer data
                    }));
                  }}
                  placeholder={inputDataObject.code.inputTipp}
                />
              </RowContainer>
              <ColumnContainer>
                <LabelL>{inputDataObject.hersteller.labelName}</LabelL>
                <StyledInput
                  type={inputDataObject.hersteller.inputType}
                  name={inputDataObject.hersteller.inputName}
                  value={formData[inputDataObject.hersteller.inputName]}
                  onChange={(e) => handleChangeUtil(e, setFormData)}
                />
              </ColumnContainer>
              <ColumnContainer>
                <LabelL>{inputDataObject.modell.labelName}</LabelL>
                <StyledInput
                  type={inputDataObject.modell.inputType}
                  name={inputDataObject.modell.inputName}
                  value={formData[inputDataObject.modell.inputName]}
                  onChange={(e) => handleChangeUtil(e, setFormData)}
                />
              </ColumnContainer>
              <ColumnContainer>
                <LabelL>{inputDataObject.baujahr.labelName}</LabelL>
                <StyledInput
                  type={inputDataObject.baujahr.inputType}
                  name={inputDataObject.baujahr.inputName}
                  value={formData[inputDataObject.baujahr.inputName]}
                  onChange={(e) => {
                    
                    if(e.target.value!==""){
                        if (/^\d*\.?\d*$/.test(e.target.value)) {
                        setFormData((prev) => ({
                          ...prev,
                          baujahr: parseInt(e.target.value, 10),
                        }));
                      }
                      } else{
                        setFormData((prev) => ({
                          ...prev,
                          baujahr: "",
                        }));
                      }
                  }}
                />
              </ColumnContainer>
              <ColumnContainer>
                <LabelL>{inputDataObject.kfz_kennzeichen.labelName}</LabelL>
                <StyledInput
                  type={inputDataObject.kfz_kennzeichen.inputType}
                  name={inputDataObject.kfz_kennzeichen.inputName}
                  value={formData[inputDataObject.kfz_kennzeichen.inputName]}
                  onChange={(e) => handleChangeUtil(e, setFormData)}
                />
              </ColumnContainer>
              <ColumnContainer>
                <LabelL>{inputDataObject.kilometer.labelName}</LabelL>
                <StyledInput
                  type={inputDataObject.kilometer.inputType}
                  name={inputDataObject.kilometer.inputName}
                  value={formData[inputDataObject.kilometer.inputName]}
                  onChange={(e) => {
                    
                    if(e.target.value!==""){
                        if (/^\d*\.?\d*$/.test(e.target.value)) {
                        setFormData((prev) => ({
                          ...prev,
                          kilometer: parseInt(e.target.value, 10),
                        }));
                      }
                      } else{
                        setFormData((prev) => ({
                          ...prev,
                          kilometer: "",
                        }));
                      }
                  }}
                />
              </ColumnContainer>
            </ColumnContainer>
            <Space style={{ height: "2rem" }} />
            <RowContainer style={{ width: "100%", justifyContent: "center" }}>
              <Button
                onClick={() => {
              
                  if (isFormValid(formData)) {
                    if (showEdit) {
                      handleUpdateCar();
                    } else {
                      handleSaveCar();
                    }
                  } else {
                    toast.warning(toastData.formInvalid);
                  }
                }}
              >
                {garageDataMain.btnTitle}
              </Button>
            </RowContainer>
          </StyledOverlayPanel>
        </Overlay>
      </SafePanel>
    </Panel>
  );
};

export default Garage;

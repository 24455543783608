import { useState } from "react";
export const customersData = {
  title: "Kunden",
  overlayTitle: "Kunde Hinzufügen",
  overlayEditTitle: "Kunde Bearbeiten",
  btnTitle: "Speichern",
  nun: "Keine Kunden gespeichert!",
};

export const useFormDataCustomer = () => {
  const [formData, setFormData] = useState({
    name: "",
    nummer: "",
    customerNumber:0,
  });

  return { formData, setFormData };
};

export const inputDataArray = 
  {
    name: {
      labelName: "Name",
      inputName: "name",
      inputType: "text",
    },
    nummer: {
      labelName: "Nummer",
      inputName: "nummer",
      inputType: "text",
    },
  };

export const useCustomerDataStat = () => {
  const [customerData, setCustomerData] = useState([""]);
  return { customerData, setCustomerData };
};
import { useState } from "react";

export const ordersMgmtDataMain = {
  title: "Auftragsverwaltung",
  plan: "Plan von",
  titleOrder: "Aufträge",
  btnTitle: "Speichern",
  noRepairs: "Keine Aufträge verfügbar",
};

export const useFormDataOrders = () => {
  const initialFormData = {
    orders: [],
  };
  const [formData, setFormData] = useState(initialFormData);

  const resetFormData = () => setFormData(initialFormData);

  return {
    formData,
    setFormData,
    resetFormData,
  };
};

export const useOrdersMgmtDataStat = () => {
  const [ordersMgmtData, setOrdersMgmtData] = useState([""]);
  return { ordersMgmtData, setOrdersMgmtData };
};

export const useOrdersMgmtSelectedDataStat = () => {
  const [ordersMgmtSelectedData, setOrdersMgmtSelectedData] = useState([""]);
  return { ordersMgmtSelectedData, setOrdersMgmtSelectedData };
};

import { useState } from "react";

export const rentDataMain = {
  title: "Mietverwaltung",
  overlayTitle: "Mietverhältnis Hinzufügen",
  overlayEditTitle: "Mietverhältnis Bearbeiten",
  btnTitle: "Speichern",
  nun: "Keine SHW-Aufträge gespeichert!",
};

export const useFormDataRent = () => {
   const initialFormData = {
    reservationStart: "",
    reservationEnd: "",
    rentalDuration:0,
    price:0,
    customPrice:false
  };

  const [formData, setFormData] = useState(initialFormData);

  const resetFormData = () => setFormData(initialFormData);

  return { formData, setFormData, resetFormData };
};
export const useFormDataRentItem = () => {
  const initialFormData = {
    items:[]
 };

 const [formItemData, setFormItemData] = useState(initialFormData);

 const resetFormItemData = () => setFormItemData(initialFormData);

 return { formItemData, setFormItemData, resetFormItemData };
};

export const inputData = {
  kunde: {
    labelName: "Kunde",
    labelPlaceholder: "Kunde Suchen...",
    inputName: "kunde",
    inputType: "object",
    input: "drop",
  },
  reservationStart: {
    labelName: "Reservierungsbeginn",
    labelPlaceholder: "Startdatum auswähen...",
    inputName: "resStart",
    inputType: "text",
    input: "text",
  },
  reservationEnd: {
    labelName: "Reservierungsende",
    labelPlaceholder: "Enddatum auswähen...",
    inputName: "resEnd",
    inputType: "text",
    input: "text",
  },
  rentalDuration: {
    labelName: "Reservierungsdauer",
    inputName: "rentDuration",
    inputType: "text",
    input: "text",
  },
  items: {
    labelName: "Was Mieten?",
    labelPlaceholder: "Mietobjekte auswähen...",
    inputName: "items",
    inputType: "text",
    input: "text",
  },
  price: {
    labelName: "Preis",
    inputName: "price",
    inputType: "number",
    input: "text",
  },
  customPrice: {
    labelName: "Preis ändern",
    labelInfo:"Gesamtpreis ändern",
    inputName: "customPrice",
    inputType: "checkbox",
    input: "text",
  },
};



export const useRentDataStat = () => {
  const [rentData, setRentData] = useState([""]);
  return { rentData, setRentData };
};

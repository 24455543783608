export const data = [
  {
    to: "/dashboard",
    text: "Dashboard",
  },
  {
    to: "/rent",
    text: "Mietverwaltung",
  },
  {
    to: "/ordermgmt",
    text: "Auftragsverwaltung",
  },
  {
    to: "/orders",
    text: "Aufträge",
  },
  {
    to: "/garage",
    text: "Garage",
  },
  {
    to: "/customers",
    text: "Kunden",
  },
  {
    to: "/inventory",
    text: "Werkstattinventar",
  },
  // {
  //   to: "/bills",
  //   text: "Rechnungen",
  // },
  // {
  //   to: "/reports",
  //   text: "Berichte",
  // },
  // {
  //   to: "/notifications",
  //   text: "Benachrichtigungen",
  // },
  // {
  //   to: "/settings",
  //   text: "Einstellungen",
  // },
];

export const dataByKey = {
  auth: { to: "/" },
  dashboard: { to: "/dashboard" },
  rent: { to: "/rent" },
  ordermgmt: { to: "/ordermgmt" },
  orders: { to: "/orders" },
  garage: { to: "/garage" },
  customers: { to: "/customers" },
  inventory: { to: "/inventory" },
  bills: { to: "/bills" },
  reports: { to: "/reports" },
  notifications: { to: "/notifications" },
  settings: { to: "/settings" },
};

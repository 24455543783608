import styled from "styled-components";
import { Link } from "react-router-dom";

export const Nav = styled.nav`
  background: black;
  height: 100%;
  width: 12rem;
  display: flex;  
  flex-direction: column;
  font-size: 1.2rem;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 50;
  transition: background-color 0.3s ease-in;
`;

export const NavbarContainer = styled.div`
  display: flex;
  justify-content: start;
  flex-direction: column;
  @media screen and (max-width: 960px) {
    width: 100%;
  }
`;

export const NavLogo = styled(Link)`
  color: #fff;
  justify-self: flex-start;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  z-index: 50;
  @media screen and (max-width: 960px) {
    margin-left: 1.1rem;
  }
`;

export const NavIcon = styled.img`
  margin-left: 0.6rem;
  width: 4rem;
`;

export const MobileIcon = styled.div`
  display: none;
  z-index: 50;
  @media screen and (max-width: 960px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }
`;

export const NavMenu = styled.ul`
  display: flex;
  padding: 0;
  margin-left: 0.9rem;
  flex-direction: column;
  background: black;
  list-style: none;
  text-align: left;
  transition: opacity 0.5s ease;
`

export const NavItem = styled.li`
  height: 4rem;
  width: 100%;
  background: black;
  cursor: pointer;
`;

export const NavLinks = styled.span`
  color: #E2E2E2;
  display: flex;
  align-items: center;
  text-decoration: none;
  height: 100%;
  &:hover {
    color: white;
    transition: all 0.3s ease;
  }
`;
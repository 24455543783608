import { useState } from "react";

export const garageDataMain = {
  title: "Garage",
  overlayTitle: "Fahrzeug Hinzufügen",
  overlayEditTitle: "Fahrzeug Bearbeiten",
  btnTitle: "Speichern",
  btnSearchTitle: "Suchen",
  nun: "Keine Fahrzeuge gespeichert!",
};

export const useFormDataGarage = () => {
  const [formData, setFormData] = useState({
    hersteller: "",
    modell: "",
    baujahr: "",
    kfz_kennzeichen: "HL-",
    code: "",
    kilometer: "",
  });

  return { formData, setFormData };
};

export const inputDataObject = {
  customer: {
    labelName: "Kunde",
    inputName:"customer",
    inputType: "text",
    inputTipp: "Kunde Suchen....",
  },
  code: {
    labelName: "Code/FIN",
    placeholder:"HSN & TSN eingeben...",
    inputName:"code",
    inputType: "text",
    inputTipp: "Code Suchen....",
  },
  hersteller: {
    labelName: "Hersteller",
    inputName:"hersteller",
    inputType: "text",
  },
  modell: {
    labelName: "Modell",
    inputName:"modell",
    inputType: "text",
  },
  baujahr: {
    labelName: "Baujahr",
    inputName:"baujahr",
    inputType: "text",
  },
  kfz_kennzeichen: {
    labelName: "KFZ Kennzeichen",
    inputName:"kfz_kennzeichen",
    inputType: "text",
  },
  kilometer: {
    labelName: "Kilometer",
    inputName:"kilometer",
    inputType: "text",
  },
};

export const useGarageDataStat = () => {
  const [garageData, setGarageData] = useState([""]);
  return { garageData, setGarageData };
};

export const useGarageDataTmpStat = () => {
  const [garageTmpData, setGarageTmpData] = useState([""]);
  return { garageTmpData, setGarageTmpData };
};
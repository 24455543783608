import { React, useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { Amplify } from "aws-amplify";
import "@aws-amplify/ui-react/styles.css";
import config from "./amplifyconfiguration.json";
import SideBar from "./components/SideBar/SideBar";
import { dataByKey } from "./data/SideBar";
import Dashboard from "./pages/Dashboard";
import Orders from "./pages/Orders";
import Garage from "./pages/Garage";
import Customers from "./pages/Customers";
import Bills from "./pages/Bills";
import Reports from "./pages/Reports";
import Notifications from "./pages/Notifications";
import Settings from "./pages/Settings";
import OrdersMgmt from "./pages/OrdersMgmt";
import { isSignedIn } from "./utils/utilities";
import Auth from "./pages/Auth";
import { ToastContainer, Zoom } from "react-toastify";
import Workshop from "./pages/Workshop";
import Rent from "./pages/Rent";
Amplify.configure(config);

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(null); // Replace with your actual auth logic
  useEffect(() => {
    const checkUser = async () => {
      const signedIn = await isSignedIn();
      setIsAuthenticated(signedIn);
    };

    checkUser();
  }, []);
  if (isAuthenticated === null) {
    return <h1>Loading...</h1>;
  }
  return (
    <Router>
      {isAuthenticated && <SideBar />}
      <Routes>

        <Route
          path={dataByKey.auth.to}
          element={
            isAuthenticated ? (
              <Navigate replace to={dataByKey.dashboard.to} />
            ) : (
              <Auth />
            )
          }
        />
        <Route>
          <Route
            path={dataByKey.dashboard.to}
            element={
              isAuthenticated ? <Dashboard /> : <Navigate replace to="/" />
            }
          />
             <Route
            path={dataByKey.rent.to}
            element={
              isAuthenticated ? <Rent /> : <Navigate replace to="/" />
            }
          />
          <Route
            path={dataByKey.inventory.to}
            element={
              isAuthenticated ? <Workshop /> : <Navigate replace to="/" />
            }
          />
             <Route
            path={dataByKey.ordermgmt.to}
            element={
              isAuthenticated ? <OrdersMgmt /> : <Navigate replace to="/" />
            }
          />
          <Route
            path={dataByKey.orders.to}
            element={isAuthenticated ? <Orders /> : <Navigate replace to="/" />}
          />
          <Route
            path={dataByKey.garage.to}
            element={isAuthenticated ? <Garage /> : <Navigate replace to="/" />}
          />
          <Route
            path={dataByKey.customers.to}
            element={
              isAuthenticated ? <Customers /> : <Navigate replace to="/" />
            }
          />
          <Route
            path={dataByKey.bills.to}
            element={isAuthenticated ? <Bills /> : <Navigate replace to="/" />}
          />
          <Route
            path={dataByKey.reports.to}
            element={
              isAuthenticated ? <Reports /> : <Navigate replace to="/" />
            }
          />
          <Route
            path={dataByKey.notifications.to}
            element={
              isAuthenticated ? <Notifications /> : <Navigate replace to="/" />
            }
          />
          <Route
            path={dataByKey.settings.to}
            element={
              isAuthenticated ? <Settings /> : <Navigate replace to="/" />
            }
          />
        </Route>
      </Routes>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        transition={Zoom}
      />
    </Router>
  );
};

export default App;

// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const RentalType = {
  "HOURLY": "HOURLY",
  "DAILY": "DAILY"
};

const { CustomerKFZ, CarKFZ, OrderKFZ, WorkshopItem, WorkshopReservation, WorkshopItemReservation, Customer, Car, Reservation } = initSchema(schema);

export {
  CustomerKFZ,
  CarKFZ,
  OrderKFZ,
  WorkshopItem,
  WorkshopReservation,
  WorkshopItemReservation,
  Customer,
  Car,
  Reservation,
  RentalType
};
import React from "react";
import { handleChangeUtil, handleSignIn } from "../utils/utilities.js";
import {
  Button,
  ColumnContainer,
  Image,
  LabelL,
  LabelXXL,
  LabelXXS,
  Panel,
  RoundedContainer,
  RowContainer,
  SafePanel,
  Space,
  StyledInput,
} from "../components/Ui/Ui.js";
import { authData, inputDataArray, useFormDataAuth } from "../data/Auth.js";
import { copyrightData, srcData } from "../data/Src.js";

const Auth = () => {
  const { formData, setFormData } = useFormDataAuth();
  const handleSignInClick = async () => {
    const signIn = await handleSignIn(formData.name, formData.password);
    if (signIn) {
      window.location.reload();
    } else {
    }
  };
  return (
    <Panel
      style={{ margin: "0rem", justifyContent: "center", alignItems: "center" }}
    >
      <SafePanel
        style={{ justifyContent: "center", alignItems: "center", width: "69%" }}
      >
        <ColumnContainer
          style={{
            height: "inherit",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <RowContainer>
            <ColumnContainer style={{ flex: "0.5" }}>
              <LabelXXL color="White" fontWeight="900">
                {authData.title}
              </LabelXXL>
              <LabelL color="white" fontWeight="300">
                {authData.desciprion}
              </LabelL>
            </ColumnContainer>
            <Space style={{ width: "2rem" }} />
            <ColumnContainer style={{ flex: "0.5" }}>
              <RoundedContainer
                pressable={false}
                style={{ backgroundColor: "grey", width: "35rem" }}
              >
                <RowContainer
                  style={{ justifyContent: "center", alignItems: "center" }}
                >
                  <Image
                    src={srcData.logo.localuri}
                    alt="Transporter"
                    style={{ width: "10rem" }}
                  />
                </RowContainer>
                <ColumnContainer style={{ gap: "1rem" }}>
                  {inputDataArray.map((el, index) => {
                    return (
                      <ColumnContainer key={index}>
                        <LabelL color="white">{el.labelName}</LabelL>
                        <StyledInput
                          type={el.inputType}
                          name={el.inputName}
                          value={formData[el.inputName]}
                          onChange={(e) => handleChangeUtil(e, setFormData)}
                        />
                      </ColumnContainer>
                    );
                  })}
                  <Button onClick={() => handleSignInClick()}>
                    {authData.btn}
                  </Button>
                </ColumnContainer>
              </RoundedContainer>
            </ColumnContainer>
          </RowContainer>
          <ColumnContainer style={{ position: "absolute", bottom: "1rem" }}>
            <RowContainer
              style={{
                justifyContent: "center",
                marginTop: "2rem",
                alignContent: "center",
              }}
            >
              <ColumnContainer style={{ flexDirection: "column-reverse" }}>
                <LabelXXS
                  style={{ opacity: "0.1", margin: "0", color: "wheat" }}
                >
                  {copyrightData.name}
                </LabelXXS>
                <LabelXXS
                  style={{ opacity: "0.1", margin: "0", color: "wheat" }}
                >
                  {copyrightData.desciprion}
                </LabelXXS>
                <LabelXXS
                  style={{
                    fontWeight: "bolder",
                    opacity: "0.1",
                    margin: "0",
                    color: "wheat",
                  }}
                >
                  {copyrightData.title}
                </LabelXXS>
              </ColumnContainer>
            </RowContainer>
          </ColumnContainer>
        </ColumnContainer>
      </SafePanel>
    </Panel>
  );
};

export default Auth;

import {
  ColumnContainer,
  Panel,
  SafePanel,
  Button,
  Space,
  LabelXXL,
  RowContainer,
  CircleButton,
  ScrollContainerSize,
  RoundedContainer,
  LabelL,
  Overlay,
  StyledOverlayPanel,
  LabelXL,
  RoundedContainerSmall,
  StyledDatePicker,
  StyledSelect,
  customStyles,
} from "../components/Ui/Ui";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import {
  ordersDataMain,
  useFormDataOrders,
  useOrdersDataStat,
  inputData,
} from "../data/Orders";
import { useEffect,useState } from "react";
import {
  addNewRecord,
  deleteRecord,
  formatDate,
  queryGarageRecordsPromised,
  queryRecords,
  queryRecordsPromised,
  subscribeToOrdersData,
  updateRecord,
} from "../utils/utilities";
import { de } from "date-fns/locale";
import { CarKFZ, CustomerKFZ, OrderKFZ } from "../models";
import { useGarageDataStat} from "../data/Garage";
import { useCustomerDataStat } from "../data/Customers";
import { Label } from "@aws-amplify/ui-react";
import DeleteIcon from "@mui/icons-material/Delete";
import jsonData from "../assets/ordersRepairData.json";
import { UnknowenData } from "../data/Src";
const Orders = () => {
  const [showOverlay, setShowOverlay] = useState(false);
  const { formData, setFormData, resetFormData } = useFormDataOrders();
  const { garageData, setGarageData } = useGarageDataStat();
  const { customerData, setCustomerData } = useCustomerDataStat();
  const { ordersData, setOrdersData } = useOrdersDataStat();
  const [showEdit, setEdit] = useState(false);
  const [orderTmp, setOrderTmp] = useState(false);
  const [filteredCars, setFilteredCars] = useState([]);
  const [garageUnpromiesd, setGarageUnpromiesd] = useState([]);

  const groupedOptions = jsonData.map((category) => ({
    label: category.mainCategory,
    options: category.items.map((item) => ({
      value: item,
      label: item,
      groupLabel: category.mainCategory, // Added for easy access
    })),
  }));

  const groupStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  };

  const groupBadgeStyles = {
    backgroundColor: "#EBECF0",
    borderRadius: "2em",
    color: "#172B4D",
    display: "inline-block",
    fontSize: 12,
    fontWeight: "normal",
    lineHeight: "1",
    minWidth: 1,
    padding: "0.16666666666667em 0.5em",
    textAlign: "center",
  };

  const formatGroupLabel = (data) => (
    <div style={groupStyles}>
      <span>{data.label}</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
  );
  useEffect(() => {
    const fetchData = async () => {
      await queryRecords(CustomerKFZ, setCustomerData);
      await queryGarageRecordsPromised(CarKFZ, setGarageData);
      await queryRecords(CarKFZ, setGarageUnpromiesd);
      await queryRecordsPromised(OrderKFZ, setOrdersData); // Wait for the data to be fetched
      const subscription = subscribeToOrdersData(setOrdersData);

      return () => {
        subscription.unsubscribe();
      };
    };

    fetchData(); // Call the async function to fetch the data
  }, [setCustomerData,setGarageData,setOrdersData]);

  const handleSaveOrder = async () => {
    await addNewRecord(OrderKFZ, formData);
    setShowOverlay(false);
    // setFormData(clearData(formData));
    resetFormData();
  };

  const handleUpdateOrder = async (formData) => {
    await updateRecord(OrderKFZ, orderTmp.id, formData, setOrdersData);
    setShowOverlay(false);
  };
  const handleDeleteOrder = async () => {
    await deleteRecord(OrderKFZ, orderTmp.id, setOrdersData);
    setShowOverlay(false);
  };
  return (
    <Panel>
      <SafePanel normal={false} allowMargin={false}>
        <ColumnContainer style={{ width: "100%" }}>
          <RowContainer style={{ alignItems: "center" }}>
            <LabelXXL color="White" fontWeight="900">
              {ordersDataMain.title}
            </LabelXXL>
            <Space style={{ width: "2rem" }} />
            <CircleButton
              onClick={() => {
                setEdit(false);
                setShowOverlay(true);
              }}
            >
              <AddIcon sx={{ color: "black" }} />
            </CircleButton>
          </RowContainer>
          <ScrollContainerSize
            style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
          >
            {ordersData &&
              ordersData.length > 0 &&
              ordersData[0] !== "" ? (ordersData.map((el, index) => {
              const customerName =
                el.customer?.name || UnknowenData.unknowenCustomer;
              const carLicensePlate =
                el.car?.kfz_kennzeichen || UnknowenData.unknowenPlates;

              // Handle potential undefined or empty 'repairs' array
              const repairs = el.repairs || [];

              return (
                <RoundedContainer
                  key={index}
                  onClick={() => {
                    setEdit(true);
                    setShowOverlay(true);

                    setFormData({
                      customer: el.customer,
                      car: el.car,
                      repairs: repairs,
                      targetDate: new Date(el.targetDate),
                    });
                    setOrderTmp(el);
                  }}
                >
                  <ColumnContainer>
                    <LabelL>{customerName}</LabelL>
                    <LabelL>{carLicensePlate}</LabelL>
                    <LabelL>{formatDate(el.targetDate)}</LabelL>

                    {/* Only render repairs if they exist */}
                    {repairs.length > 0 ? (
                      repairs.map((repair, idx) => (
                        <LabelL key={idx}>{repair}</LabelL>
                      ))
                    ) : (
                      <LabelL>No repairs available</LabelL>
                    )}

                    <RowContainer
                      style={{ justifyContent: "flex-end", opacity: "0.3" }}
                    >
                      {/* You can uncomment and use this line if you want to display a 'nummer' */}
                      {/* <LabelL>{el.nummer}</LabelL> */}
                    </RowContainer>
                  </ColumnContainer>
                </RoundedContainer>
              );
            })):(  <LabelL color="white" fontWeight="600">
                                        {ordersDataMain.nun}
                                      </LabelL>)}
          </ScrollContainerSize>
        </ColumnContainer>
        <Overlay show={showOverlay}>
          <StyledOverlayPanel>
            <RowContainer
              style={{ alignItems: "center", justifyContent: "space-between" }}
            >
              <LabelXL fontWeight="600">
                {showEdit
                  ? ordersDataMain.overlayEditTitle
                  : ordersDataMain.overlayTitle}
              </LabelXL>
              <RowContainer>
                <CircleButton
                  style={{ visibility: showEdit ? "visible" : "hidden" }}
                  onClick={() => {
                    handleDeleteOrder();
                    setShowOverlay(false);
                  }}
                >
                  <DeleteIcon sx={{ color: "black" }} />
                </CircleButton>
                <Space style={{ width: "1rem" }} />
                <CircleButton
                  onClick={() => {
                    setShowOverlay(false);
                    resetFormData();
                  }}
                >
                  <CloseIcon sx={{ color: "black" }} />
                </CircleButton>
              </RowContainer>
            </RowContainer>
            <Space style={{ height: "1rem" }} />
            <ColumnContainer style={{ gap: "1rem" }}>
              <StyledSelect
                styles={customStyles}
                value={
                  formData.customer
                    ? {
                        value: formData.customer.customerNumber, // Set the selected value based on the current form data
                        label: (
                          <ColumnContainer
                            style={{ padding: "8px", cursor: "pointer" }}
                          >
                            <Label>{formData.customer.name}</Label>
                            <Label>{formData.customer.nummer}</Label>
                            <Label>{formData.customer.customerNumber}</Label>
                          </ColumnContainer>
                        ),
                      }
                    : null
                }
                options={customerData.map((customer) => ({
                  value: customer.customerNumber, // Unique identifier
                  label: (
                    <ColumnContainer
                      style={{ padding: "8px", cursor: "pointer" }}
                    >
                      <Label>{customer.name}</Label>
                      <Label>{customer.nummer}</Label>
                      <Label>{customer.customerNumber}</Label>
                    </ColumnContainer>
                  ),
                }))}
                onChange={(selectedOption) => {
                  const selectedCustomer = customerData.find(
                    (customer) =>
                      customer.customerNumber === selectedOption.value
                  );
                  setFormData((prevFormData) => ({
                    ...prevFormData,
                    customer: selectedCustomer, // Update form with selected customer data
                  }));
                  const filtered = garageData.filter(
                    (car) => car.customer.id === selectedCustomer.id
                  );

                  setFilteredCars(filtered);
                }}
                placeholder={inputData.kunde.placeholder}
              />
              <ColumnContainer>
                {/* <LabelL>{inputData.targetdate.labelName}</LabelL> */}
                <StyledDatePicker
                  selected={
                    formData.targetDate
                      ? new Date(formData.targetDate)
                      : new Date(new Date().setHours(9, 0, 0, 0))
                  } // Use formData.reservationStart, convert to Date object
                  onChange={(date) => {
                    setFormData({
                      ...formData,
                      targetDate: date.toISOString(), // Update reservationStart in formData
                    });
                  }}
                  showTimeInput
                  timeIntervals={15}
                  dateFormat="dd.MM.yyyy HH:mm"
                  timeFormat="HH:mm"
                  locale={de}
                  placeholderText={inputData.targetdate.placeholder}
                  type={inputData.targetdate.labelName}
                  name={inputData.targetdate.labelName}
                  value={new Date(formData.targetDate)} // Controlled value for the start date
                />
              </ColumnContainer>
              <StyledSelect
                styles={customStyles}
                value={
                  formData.car
                    ? {
                        value: formData.car.kfz_kennzeichen, // Set the selected value based on the current form data
                        label: (
                          <ColumnContainer
                            style={{ padding: "8px", cursor: "pointer" }}
                          >
                            <Label>{formData.car.hersteller}</Label>
                            <Label>{formData.car.modell}</Label>
                            <Label>{formData.car.kfz_kennzeichen}</Label>
                          </ColumnContainer>
                        ),
                      }
                    : null
                }
                options={filteredCars.map((car) => ({
                  value: car.kfz_kennzeichen, // Unique identifier
                  label: (
                    <ColumnContainer
                      style={{ padding: "8px", cursor: "pointer" }}
                    >
                      <Label>{car.hersteller}</Label>
                      <Label>{car.modell}</Label>
                      <Label>{car.kfz_kennzeichen}</Label>
                    </ColumnContainer>
                  ),
                }))}
                onChange={(selectedOption) => {
                  const selectedCar = garageUnpromiesd.find(
                    (car) => car.kfz_kennzeichen === selectedOption.value
                  );
                  setFormData((prevFormData) => ({
                    ...prevFormData,
                    car: selectedCar, // Update form with selected customer data
                  }));
                }}
                placeholder={inputData.fahrzeug.placeholder}
              />
              <RoundedContainer>
                <ScrollContainerSize style={{ height: "15vh" }}>
                  {formData.repairs.map((el, index) => (
                    <RoundedContainerSmall
                      key={index}
                      onClick={() => {
                        setFormData((prevFormData) => ({
                          ...prevFormData,
                          repairs: prevFormData.repairs.filter(
                            (repair) => repair !== el
                          ),
                        }));
                      }}
                    >
                      <Label color="white">{el}</Label>
                    </RoundedContainerSmall>
                  ))}
                </ScrollContainerSize>
              </RoundedContainer>
              <ColumnContainer>
                <StyledSelect
                  options={groupedOptions}
                  formatGroupLabel={formatGroupLabel}
                  placeholder={inputData.repairs.placeholder}
                  styles={{
                    ...customStyles, // Spread customStyles
                    menu: (provided) => ({
                      ...provided,
                      ...customStyles?.menu?.(provided), // If customStyles has a menu style, merge it
                      zIndex: 9999, // Add or override the zIndex
                    }),
                  }}
                  onChange={(selectedOption, actionMeta) => {
                    // Extract the category and item from the selectedOption
                    const category = selectedOption.groupLabel; // Group label is the category
                    const item = selectedOption.label; // Option label is the item

                    const selectedRepair = `${category}: ${item}`;

                    // Add the selected repair to the repairs array, avoiding duplicates
                    setFormData((prevFormData) => ({
                      ...prevFormData,
                      repairs: prevFormData.repairs.includes(selectedRepair)
                        ? prevFormData.repairs // Don't add duplicates
                        : [...prevFormData.repairs, selectedRepair], // Add new repair
                    }));
                  }}
                />
              </ColumnContainer>
            </ColumnContainer>
            <Space style={{ height: "2rem" }} />
            <RowContainer style={{ width: "100%", justifyContent: "center" }}>
              <Button
                onClick={() => {
                  if (showEdit) {
                    setFormData((prevState) => {
                      const updatedState = {
                        ...prevState,
                        targetDate: prevState.targetDate.toISOString(), // Update reservationStart in formData
                      };
                      handleUpdateOrder(updatedState); // Trigger the next function after state is updated

                      return updatedState;
                    });
                  } else {
                    handleSaveOrder();
                  }
                }}
              >
                {ordersDataMain.btnTitle}
              </Button>
            </RowContainer>
          </StyledOverlayPanel>
        </Overlay>
      </SafePanel>
    </Panel>
  );
};

export default Orders;

import {
  ColumnContainer,
  Panel,
  SafePanel,
  Button,
  Space,
  LabelXXL,
  RowContainer,
  CircleButton,
  ScrollContainerSize,
  RoundedContainer,
  LabelL,
  Overlay,
  StyledOverlayPanel,
  LabelXL,
  StyledInput,
  RoundedContainerSmall,
  StyledDatePicker,
  StyledSelect,
  customStyles,
} from "../components/Ui/Ui";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import {
  calculateRentalDuration,
  deleteRecord,
  formatDate,
  handleChangeUtil,
  preisRechner,
  queryRecords,
  queryRentRecordsPromised,
  subscribeToRentData,
  updateRecord,
} from "../utils/utilities";
import {
  CustomerKFZ,
  WorkshopItem,
  WorkshopItemReservation,
  WorkshopReservation,
} from "../models";
import workshopItemsData from "../assets/workshop.json";
import { useCustomerDataStat } from "../data/Customers";
import { Label } from "@aws-amplify/ui-react";
import DeleteIcon from "@mui/icons-material/Delete";
import "react-datepicker/dist/react-datepicker.css"; // Required styles
import { de } from "date-fns/locale";
import {
  inputData,
  rentDataMain,
  useFormDataRent,
  useFormDataRentItem,
  useRentDataStat,
} from "../data/Rent";
import { useWorkshopDataStat } from "../data/Workshop";
import { DataStore } from "aws-amplify/datastore";
import { toastData, UnknowenData } from "../data/Src";
import { toast } from "react-toastify";
const Rent = () => {
  const [showOverlay, setShowOverlay] = useState(false);
  const { formData, setFormData, resetFormData } = useFormDataRent();
  const { formItemData, setFormItemData, resetFormItemData } =
    useFormDataRentItem();
  const { customerData, setCustomerData } = useCustomerDataStat();
  const { workshopData, setWorkshopData } = useWorkshopDataStat();
  const { rentData, setRentData } = useRentDataStat();
  const [showEdit, setEdit] = useState(false);
  const [rentTmp, setRentTmp] = useState(false);
  const [selectKey, setSelectKey] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      await queryRecords(CustomerKFZ, setCustomerData);
      await queryRentRecordsPromised(WorkshopReservation, setRentData);
      await queryRecords(WorkshopItem, setWorkshopData);
      const subscription = subscribeToRentData(setRentData);

      return () => {
        subscription.unsubscribe();
      };
    };

    fetchData(); // Call the async function to fetch the data
  }, [setCustomerData,setRentData,setWorkshopData]);
  const handleSaveRent = async () => {
    try {
      const savedReservation = await DataStore.save(
        new WorkshopReservation({
          customer: formData.customer,
          reservationStart: formData.reservationStart.toISOString(),
          reservationEnd: formData.reservationEnd.toISOString(),
          rentalDuration: formData.rentalDuration,
          price: formData.price,
          customPrice: formData.customPrice,
        })
      );

      // Step 2: Wait for the promises inside the reservation to resolve (like items)
      const resolvedItems = await Promise.all(
        formItemData.items.map((item) =>
          DataStore.save(
            new WorkshopItemReservation({
              workshopItem: item,
              workshopReservation: savedReservation, // Pass the entire savedReservation object
            })
          )
        )
      );

      // Step 3: Now update the reservation with the resolved items (you can store full item objects or just IDs)

      const updatedReservation = await DataStore.save(
        WorkshopReservation.copyOf(savedReservation, (updated) => {
          updated.items = resolvedItems.map((item) => item.id); // Update with item IDs or full objects
        })
      );
      setShowOverlay(false);
      resetFormData();
      resetFormItemData();
      toast.success(toastData.dataSaved);
      return updatedReservation;
    } catch (error) {
      toast.error(toastData.actionError+" "+toastData.rentError)
      //throw error;
    }
  };
  const handleUpdateRent = async () => {
    const formItemDataIds = formItemData.items.map((item) => item.id);
    const formDataItemIds = formData.items.map(
      (item) => item.workshopItemReservationsId
    );

    // Find items to add (exist in formItemData but not in formdata)
    const itemsToAdd = formItemData.items.filter(
      (item) => !formDataItemIds.includes(item.id)
    );
    // Find items to delete (exist in formdata but not in formItemData)
    const itemsToDelete = formData.items.filter(
      (item) => !formItemDataIds.includes(item.workshopItemReservationsId)
    );

    if (itemsToAdd.length > 0) {
      try {
        // Step 1: Retrieve the existing reservation
        const reservation = await DataStore.query(
          WorkshopReservation,
          (r) => r.id.eq(rentTmp.id) // Use formData.id to fetch the correct reservation
        );

        if (!reservation || reservation.length === 0) {
          toast.error(toastData.actionError+" "+toastData.rentError1)
        //  throw new Error("Reservation not found.");
        }

        // Step 2: Resolve new items
        const resolvedItems = await Promise.all(
          itemsToAdd.map((item) =>
            DataStore.save(
              new WorkshopItemReservation({
                workshopItem: item,
                workshopReservation: reservation[0], // Associate with the existing reservation
              })
            )
          )
        );

        // Step 3: Update the reservation with resolved items
        const updatedReservation = await DataStore.save(
          WorkshopReservation.copyOf(reservation[0], (updated) => {
            const currentItems = Array.isArray(reservation[0].items)
              ? reservation[0].items
              : [];
            const newItems = resolvedItems.map((item) => item); // Add full objects or just item IDs
            updated.items = [...currentItems, ...newItems]; // Merge items
          })
        );
        setShowOverlay(false);
        resetFormData();
        resetFormItemData();
        toast.success(toastData.dataSaved);
        return updatedReservation;
      } catch (error) {
        toast.error(toastData.actionError+" "+toastData.rentError2)
     //   throw error;
      }
    }

    if (itemsToDelete.length > 0) {
      try {
        // Step 1: Retrieve the existing reservation
        const reservation = await DataStore.query(
          WorkshopReservation,
          (r) => r.id.eq(rentTmp.id) // Use rentTmp.id to fetch the correct reservation
        );

        if (!reservation || reservation.length === 0) {
          toast.error(toastData.actionError+" "+toastData.rentError1)
        //  throw new Error("Reservation not found.");
        }

        // Step 2: Delete the items
        await Promise.all(
          itemsToDelete.map((item) =>
            DataStore.delete(
              WorkshopItemReservation,
              (r) => r.id.eq(item.id) // Use the ID to delete specific items
            )
          )
        );

        // Step 3: Update the reservation after deleting items
        const updatedReservation = await DataStore.save(
          WorkshopReservation.copyOf(reservation[0], (updated) => {
            const currentItems = Array.isArray(reservation[0].items)
              ? reservation[0].items
              : [];
            const remainingItems = currentItems.filter(
              (item) =>
                !itemsToDelete.some((deleteItem) => deleteItem.id === item.id)
            );
            updated.items = remainingItems; // Update the reservation to exclude deleted items
          })
        );
        setShowOverlay(false);
        resetFormData();
        resetFormItemData();
        toast.success(toastData.dataSaved);
        return updatedReservation;
      } catch (error) {
        toast.error(toastData.actionError+" "+toastData.rentError3)
        //console.error("Error deleting items from reservation:", error);
        //throw error;
      }
    }

    const updatedFormData = {
      ...formData, // Spread the existing formData
      reservationStart: formData.reservationStart
        ? formData.reservationStart.toISOString()
        : null,
      reservationEnd: formData.reservationEnd
        ? formData.reservationEnd.toISOString()
        : null,
    };

    // Immediately use the updated formData to call updateRecord
    await updateRecord(
      WorkshopReservation,
      rentTmp.id,
      updatedFormData,
      setRentData
    );

    // Optionally, update state if you need the updated formData to be reflected elsewhere
    setFormData(updatedFormData);
    //await updateRecord(WorkshopReservation, rentTmp.id, formData, setRentData);
    setShowOverlay(false);
  };
  const handleDeleteRent = async () => {
    await deleteRecord(WorkshopReservation, rentTmp.id, setRentData);
    setShowOverlay(false);
  };
  const handleSelect = (item) => {
    // Add the selected repair to the repairs array, avoiding duplicates
    setFormItemData((prevFormData) => {
      const updatedItems = prevFormData.items.includes(item)
        ? prevFormData.items // Don't add duplicates
        : [...prevFormData.items, item]; // Add new repair

      // Log inside the update logic

      // Call `preisRechner` with updated data
      preisRechner(
        formData.rentalDuration,
        { ...prevFormData, items: updatedItems },
        setFormData
      );

      return {
        ...prevFormData,
        items: updatedItems,
      };
    });
  };
  return (
    <Panel>
      <SafePanel normal={false} allowMargin={false}>
        <ColumnContainer style={{ width: "100%" }}>
          <RowContainer style={{ alignItems: "center" }}>
            <LabelXXL color="White" fontWeight="900">
              {rentDataMain.title}
            </LabelXXL>
            <Space style={{ width: "2rem" }} />
            <CircleButton
              onClick={() => {
                setEdit(false);
                setShowOverlay(true);
              }}
            >
              <AddIcon sx={{ color: "black" }} />
            </CircleButton>
          </RowContainer>
          <ScrollContainerSize
            style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
          >
            {rentData &&
              rentData.length > 0 &&
              rentData[0] !== "" ?  (rentData.map((el, index) => {
              const customerName = el.customer?.name || UnknowenData.unknowenCustomer;

              // Handle potential undefined or empty 'repairs' array
             

              return (
                <RoundedContainer
                  key={index}
                  onClick={() => {
                    setEdit(true);
                    setShowOverlay(true);
                    setFormData({
                      customer: el.customer,
                      reservationStart: new Date(el.reservationStart),
                      reservationEnd: new Date(el.reservationEnd),
                      rentalDuration: el.rentalDuration,
                      items: el.items,
                      price: el.price,
                      customPrice: el.customPrice,
                    });
                    setFormItemData(() => ({
                      items: el.items.map((item) => item.workshopItem), // Directly extract workshopItem from each item
                    }));

                    setRentTmp(el);
                  }}
                >
                  <ColumnContainer>
                    <LabelL>{customerName}</LabelL>
                    <RowContainer style={{ gap: "1rem" }}>
                      <LabelL>{formatDate(el.reservationStart)}</LabelL>
                      <LabelL>bis</LabelL>
                      <LabelL>{formatDate(el.reservationEnd)}</LabelL>
                      <LabelL>{el.rentalDuration} Stunde(n)</LabelL>
                    </RowContainer>

                    <RowContainer
                      style={{ justifyContent: "flex-end", opacity: "0.3" }}
                    >
                      {/* You can uncomment and use this line if you want to display a 'nummer' */}
                      {/* <LabelL>{el.nummer}</LabelL> */}
                    </RowContainer>
                  </ColumnContainer>
                </RoundedContainer>
              );
            })):(    <LabelL color="white" fontWeight="600">
                                        {rentDataMain.nun}
                                      </LabelL>)}
          </ScrollContainerSize>
        </ColumnContainer>
        <Overlay show={showOverlay}>
          <StyledOverlayPanel>
            <RowContainer
              style={{ alignItems: "center", justifyContent: "space-between" }}
            >
              <LabelXL fontWeight="600">
                {showEdit
                  ? rentDataMain.overlayEditTitle
                  : rentDataMain.overlayTitle}
              </LabelXL>
              <RowContainer>
                <CircleButton
                  style={{ visibility: showEdit ? "visible" : "hidden" }}
                  onClick={() => {
                    handleDeleteRent();
                    setShowOverlay(false);
                  }}
                >
                  <DeleteIcon sx={{ color: "black" }} />
                </CircleButton>
                <Space style={{ width: "1rem" }} />
                <CircleButton
                  onClick={() => {
                    setShowOverlay(false);
                    resetFormData();
                    resetFormItemData();
                  }}
                >
                  <CloseIcon sx={{ color: "black" }} />
                </CircleButton>
              </RowContainer>
            </RowContainer>
            <Space style={{ height: "1rem" }} />
            <ColumnContainer style={{ gap: "1rem" }}>
              <ColumnContainer>
                <LabelL>{inputData.kunde.labelName}</LabelL>
                <StyledSelect
                  styles={customStyles}
                  value={
                    formData.customer
                      ? {
                          value: formData.customer.customerNumber, // Set the selected value based on the current form data
                          label: (
                            <ColumnContainer
                              style={{ padding: "8px", cursor: "pointer" }}
                            >
                              <Label>{formData.customer.name}</Label>
                              <Label>{formData.customer.nummer}</Label>
                              <Label>{formData.customer.customerNumber}</Label>
                            </ColumnContainer>
                          ),
                        }
                      : null
                  }
                  options={customerData.map((customer) => ({
                    value: customer.customerNumber, // Unique identifier
                    label: (
                      <ColumnContainer
                        style={{ padding: "8px", cursor: "pointer" }}
                      >
                        <Label>{customer.name}</Label>
                        <Label>{customer.nummer}</Label>
                        <Label>{customer.customerNumber}</Label>
                      </ColumnContainer>
                    ),
                  }))}
                  onChange={(selectedOption) => {
                    const selectedCustomer = customerData.find(
                      (customer) =>
                        customer.customerNumber === selectedOption.value
                    );
                    setFormData((prevFormData) => ({
                      ...prevFormData,
                      customer: selectedCustomer, // Update form with selected customer data
                    }));
                  }}
                  placeholder={inputData.kunde.labelPlaceholder}
                />
              </ColumnContainer>
              <RowContainer style={{ justifyContent: "space-between" }}>
                <ColumnContainer>
                  <LabelL>{inputData.reservationStart.labelName}</LabelL>
                  <StyledDatePicker
                    selected={
                      formData.reservationStart
                        ? new Date(formData.reservationStart)
                        : new Date(new Date().setHours(9, 0, 0, 0))
                    } // Use formData.reservationStart, convert to Date object
                    onChange={(date) => {
                      const rentalDuration = formData.reservationEnd
                        ? calculateRentalDuration(date, formData.reservationEnd)
                        : null;

                      if (rentalDuration !== null) {
                        setFormData({
                          ...formData,
                          reservationStart: date, // Update reservationStart in formData
                          rentalDuration: rentalDuration, // Set rentalDuration in "xx Stunden" format
                        });

                        // Call preisRechner only if reservationEnd is set
                        preisRechner(rentalDuration, formItemData, setFormData);
                      } else {
                        setFormData({
                          ...formData,
                          reservationStart: date, // Update reservationStart in formData
                        });
                      }
                    }}
                    showTimeInput
                    timeIntervals={15}
                    dateFormat="dd.MM.yyyy HH:mm"
                    timeFormat="HH:mm"
                    locale={de}
                    placeholderText={inputData.reservationStart.labelPlaceholder}
                    type={inputData.reservationStart.labelName}
                    name={inputData.reservationStart.labelName}
                    value={formData.reservationStart} // Controlled value for the start date
                  />
                </ColumnContainer>
                <ColumnContainer>
                  <LabelL>{inputData.reservationEnd.labelName}</LabelL>
                  <StyledDatePicker
                    selected={
                      formData.reservationEnd
                        ? new Date(formData.reservationEnd)
                        : new Date(new Date().setHours(9, 0, 0, 0))
                    } // Use formData.reservationStart, convert to Date object
                    onChange={(date) => {
                      const rentalDuration = calculateRentalDuration(
                        formData.reservationStart,
                        date
                      );

                      // Update both reservationEnd and rentalDuration in a single setFormData call
                      setFormData({
                        ...formData,
                        reservationEnd: date, // Update reservationEnd in formData
                        rentalDuration: rentalDuration, // Set rentalDuration in "xx Stunden" format
                      });

                      preisRechner(rentalDuration, formItemData, setFormData);
                    }}
                    showTimeInput
                    timeIntervals={15}
                    dateFormat="dd.MM.yyyy HH:mm"
                    timeFormat="HH:mm"
                    locale={de}
                    placeholderText={inputData.reservationEnd.labelPlaceholder}
                    type={inputData.reservationEnd.labelName}
                    name={inputData.reservationEnd.labelName}
                    value={formData.reservationEnd} // Controlled value for the start date
                  />
                </ColumnContainer>
                <ColumnContainer>
                  <LabelL>{inputData.rentalDuration.labelName}</LabelL>
                  <StyledInput
                    type={inputData.rentalDuration.labelName}
                    name={inputData.rentalDuration.labelName}
                    value={`${formData.rentalDuration} Stunde(n)`}
                    disabled={true}
                  />
                </ColumnContainer>
              </RowContainer>
              <LabelL>{inputData.items.labelName}</LabelL>
              <RoundedContainer>
                <ScrollContainerSize style={{ height: "15vh", flexDirection:"column", display:"flex" }}>
                  {formItemData.items.map((el, index) => (
                    <RoundedContainerSmall
                      key={index}
                      onClick={() => {
                        setFormItemData((prevFormData) => {
                          const updatedItems = prevFormData.items.filter(
                            (item) => item !== el
                          );
                          const updatedFormData = {
                            ...prevFormData,
                            items: updatedItems,
                          };

                          // Call preisRechner with the updated form data
                          preisRechner(
                            formData.rentalDuration,
                            updatedFormData,
                            setFormData
                          );

                          return updatedFormData;
                        });
                      }}
                    >
                      <ColumnContainer>
                        <Label color="white">{el.name}</Label>
                        <Label color="white">{el.type}</Label>
                        <Label color="white">{el.normalPrice}</Label>
                      </ColumnContainer>
                    </RoundedContainerSmall>
                  ))}
                </ScrollContainerSize>
              </RoundedContainer>
              <StyledSelect
                styles={customStyles}
                key={selectKey} // Dynamically change the key to reset the component
                options={workshopData.map((workshop) => ({
                  value: workshop.name, // or any unique identifier
                  label: (
                    <ColumnContainer
                      style={{ padding: "8px", cursor: "pointer" }}
                    >
                      <Label>
                        {workshopItemsData.find(
                          (option) => option.value === workshop.type
                        )?.label || "null"}
                      </Label>
                      <Label>{workshop.name}</Label>
                      <Label>{workshop.normalPrice}€</Label>
                    </ColumnContainer>
                  ),
                }))}
                onChange={(selectedOption) => {
                  const selectedWorkshop = workshopData.find(
                    (workshop) => workshop.name === selectedOption.value
                  );

                  handleSelect(selectedWorkshop);

                  // Reset the select component by changing the key
                  setSelectKey((prevKey) => prevKey + 1);
                }}
                placeholder={inputData.items.labelPlaceholder}
              />

              <RowContainer style={{ gap: "2rem" }}>
                <ColumnContainer>
                  <LabelL>{inputData.customPrice.labelName}</LabelL>
                  <RowContainer>
                    <Label>{inputData.customPrice.labelInfo}</Label>
                    <input
                      type={inputData.customPrice.inputType} // Assuming this is "checkbox"
                      name={inputData.customPrice.inputName}
                      checked={formData.customPrice} // Bind "checked" to formData
                      onChange={(e) => {
                        // Use "checked" instead of "value"
                        handleChangeUtil(
                          {
                            target: {
                              name: e.target.name,
                              value: e.target.checked,
                            },
                          },
                          setFormData
                        );
                      }}
                    />
                  </RowContainer>
                </ColumnContainer>
                <ColumnContainer>
                  <LabelL>{inputData.price.labelName}</LabelL>

                  <StyledInput
                    type={inputData.price.labelName}
                    name={inputData.price.inputName}
                    value={formData.price}
                    onChange={(e) => {
                      if(e.target.value!==""){
                        if (/^\d*\.?\d*$/.test(e.target.value)) {
                        setFormData((prev) => ({
                          ...prev,
                          price: parseInt(e.target.value, 10),
                        }));
                      }
                      } else{
                        setFormData((prev) => ({
                          ...prev,
                          price: "",
                        }));
                      }
                    }}
                    disabled={!formData.customPrice}
                  />
                </ColumnContainer>
              </RowContainer>
            </ColumnContainer>
            <Space style={{ height: "2rem" }} />
            <RowContainer style={{ width: "100%", justifyContent: "center" }}>
              <Button
                onClick={() => {
                  if (showEdit) {
                    handleUpdateRent();
                  } else {
                    handleSaveRent();
                  }
                }}
              >
                {rentDataMain.btnTitle}
              </Button>
            </RowContainer>
          </StyledOverlayPanel>
        </Overlay>
      </SafePanel>
    </Panel>
  );
};

export default Rent;

import { useState } from "react";

export const authData = {
  title:"LTM KFZ Verwaltungssystem",
  desciprion:"Das Werkstattverwaltungssystem bietet eine umfassende Lösung für die effiziente Organisation und Verwaltung Ihrer Werkstatt. Es ermöglicht die einfache Erfassung von Fahrzeugdaten sowie die Erstellung und Verwaltung von Aufträgen. Zusätzlich bietet das System die Möglichkeit, Arbeitspläne zu erstellen und Arbeitsaufträge für den täglichen Einsatz zu strukturieren. Abschließend können alle relevanten Informationen, einschließlich durchgeführter Reparaturen, in professionellen Dokumenten für den Ausdruck bereitgestellt werden.",
  btn:"Anmelden"
  };

  export const useFormDataAuth = () => {
    const [formData, setFormData] = useState({
      name: "",
      password: "",
    });
  
    return { formData, setFormData };
  };
  
  export const inputDataArray = [
    {
      labelName: "Name",
      inputName: "name",
      inputType: "text",
    },
    {
      labelName: "Passwort",
      inputName: "password",
      inputType: "password",
    },
  ];
  
  export const useAuthDataStat = () => {
    const [authData, setAuthData] = useState([""]);
    return { authData, setAuthData };
  };
const LOCAL_URI = "./assets/";
const GLOBAL_URI = "https://www.example.com/assets/";
export const srcData = {
  logo: {
    localuri: `${LOCAL_URI}images/logo/47fcebf5fa98f74a46b161cf20eb8b0b.png`,
    globaluri: `${GLOBAL_URI}images/logo/47fcebf5fa98f74a46b161cf20eb8b0b.png`,
    alt: "LTM Transport und Autoservice",
  },
  location: {
    localuri: `${LOCAL_URI}images/location/6036cb33152ff96767202a6c20facb4b.png`,
    globaluri: `${GLOBAL_URI}images/location/6036cb33152ff96767202a6c20facb4b.png`,
    alt: "LTM Standort",
  },
  transporter1: {
    localuri: `${LOCAL_URI}images/transporter/49c0fe78d4178411b46c015f89f9daf1.jpeg`,
    globaluri: `${GLOBAL_URI}images/transporter/49c0fe78d4178411b46c015f89f9daf1.jpeg`,
    alt: "LTM Transporter",
  },
  kfz1: {
    localuri: `${LOCAL_URI}images/kfz/35dcb5c83066cf71a021452ae0697f1a.jpeg`,
    globaluri: `${GLOBAL_URI}images/kfz/35dcb5c83066cf71a021452ae0697f1a.jpeg`,
    alt: "LTM KFZ",
  },
  euroIcon: {
    localuri: `${LOCAL_URI}icons/svg/euro.svg`,
    globaluri: `${GLOBAL_URI}icons/svg/euro.svg`,
    alt: "LTM KFZ",
  },
};

export const copyrightData = {
  title: "© 2025 LTM (Admin Web v1.0)",
  desciprion: "designed and developed in Lübeck",
  name: "by Adham Kamala",
};

export const toastData = {
  loginSuccess: "Anmeldung erfolgreich!",
  loginFailure: "Falscher Benutzername oder Passwort.",
  logoutSuccess: "Erfolgreich abgemeldet.",
  passwordUpdated: "Passwort wurde erfolgreich aktualisiert.",
  actionError: "Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut.",
  dataSaved: "Erfolgreich gespeichert!",
  dataDeleted: "Erfolgreich gelöscht!",
  dataUpdated: "Erfolgreich bearbeitet!",
  formInvalid: "alle Felder dürfen nicht leer sein!",
  dashboardError: "D01",
  dashboardError2: "D02",
  dashboardError3: "D03",
  dashboardError4: "D04",

  rentError: "R01",
  rentError2: "R02",
  rentError3: "R03",
  rentError4: "R04",
  orderMgmtError: "OM01",
  orderMgmtError2: "OM02",
  orderMgmtError3: "OM03",
  orderMgmtError4: "OM04",
  orderError: "O01",
  orderError2: "O02",
  orderError3: "O03",
  orderError4: "O04",
  garageError: "G01",
  garageError2: "G02",
  garageError3: "G03",
  garageError4: "G04",
  customerError: "C01",
  customerError2: "C02",
  customerError3: "C03",
  customerError4: "C04",
  werkstattError: "W01",
  werkstattError2: "W02",
  werkstattError3: "W03",
  werkstattError4: "W04",
};

export const UnknowenData = {
  unknowenCustomer: "Unbekannter Kunde",
  unknowenData: "Datei Unbekannt",
  unknowenPlates: "Kennzeichen Unbekannt",
};

import {
  ColumnContainer,
  Panel,
  SafePanel,
  Space,
  LabelXXL,
  RowContainer,
  CircleButton,
  RoundedContainer,
  LabelL,
  ScrollContainer,
} from "../components/Ui/Ui";
import { useEffect} from "react";
import {
  generatePDFAndPrint,
  getCurrentDate,
  queryRecordsPromised,
  subscribeToOrdersData,
} from "../utils/utilities";
import PrintIcon from '@mui/icons-material/Print';
import {
  ordersMgmtDataMain,
  useOrdersMgmtDataStat,
  useOrdersMgmtSelectedDataStat,
} from "../data/OrdersMgmt";
import { UnknowenData } from "../data/Src";
import { OrderKFZ } from "../models";
const OrdersMgmt = () => {
  const { ordersMgmtData, setOrdersMgmtData } = useOrdersMgmtDataStat();
  const { ordersMgmtSelectedData, setOrdersMgmtSelectedData } =
    useOrdersMgmtSelectedDataStat();
  useEffect(() => {
    const fetchData = async () => {
      await queryRecordsPromised(OrderKFZ, setOrdersMgmtData); // Wait for the data to be fetched
      const subscription = subscribeToOrdersData(setOrdersMgmtData);
      return () => {
        subscription.unsubscribe();
    
      };
    };

    fetchData(); // Call the async function to fetch the data
  }, [setOrdersMgmtData]);
  return (
    <Panel>
      <SafePanel normal={false} allowMargin={false}>
        <ColumnContainer style={{ width: "100%" }}>
          <RowContainer style={{ alignItems: "center" }}>
            <LabelXXL color="White" fontWeight="900">
              {ordersMgmtDataMain.title} - {getCurrentDate()}
            </LabelXXL>
            <Space style={{ width: "2rem" }} />
          </RowContainer>
          <Space style={{ height: "2rem" }} />
          <RowContainer style={{ height: "100vh" }}>
            <ColumnContainer style={{ flex: "0.3" }}>
              <LabelL color="white" fontWeight="600">
                {ordersMgmtDataMain.titleOrder}
              </LabelL>
              <Space style={{ height: "1rem" }} />
              <ScrollContainer
                style={{ flex: "auto",display: "flex", flexDirection:"column", gap: "1rem", padding:"0.6rem" }}
              >
                {ordersMgmtData.map((el, index) => {
                  const customerName = el.customer?.name || UnknowenData.unknowenCustomer;
                  const carLicensePlate =
                    el.car?.kfz_kennzeichen || UnknowenData.unknowenPlates;

                  // Handle potential undefined or empty 'repairs' array
                  const repairs = el.repairs || [];

                  return (
                    <RoundedContainer
                      key={index}
                      onClick={() => {
                        setOrdersMgmtSelectedData((prevFormData) => ({
                          ...prevFormData,
                          models: prevFormData.models?.includes(el) // Check if models array exists and includes `el`
                            ? prevFormData.models // If `el` exists, return the existing models array
                            : [...(prevFormData.models || []), el], // Otherwise, add `el` to the models array
                        }));
                      }}
                    >
                      <ColumnContainer>
                        <LabelL>{customerName}</LabelL>
                        <LabelL>{carLicensePlate}</LabelL>

                        {/* Only render repairs if they exist */}
                        {repairs.length > 0 ? (
                          repairs.map((repair, idx) => (
                            <LabelL key={idx}>{repair}</LabelL>
                          ))
                        ) : (
                          <LabelL>{ordersMgmtDataMain.noRepairs}</LabelL>
                        )}

                        <RowContainer
                          style={{ justifyContent: "flex-end", opacity: "0.3" }}
                        >
                          {/* You can uncomment and use this line if you want to display a 'nummer' */}
                          {/* <LabelL>{el.nummer}</LabelL> */}
                        </RowContainer>
                      </ColumnContainer>
                    </RoundedContainer>
                  );
                })}
              </ScrollContainer>
            </ColumnContainer>
            <Space style={{ width: "1rem" }} />
            <ColumnContainer style={{ flex: "1" }}>
              <LabelL color="white" fontWeight="600">
                {ordersMgmtDataMain.plan} - {getCurrentDate()}
              </LabelL>
              <Space style={{ height: "1rem" }} />
              <ColumnContainer style={{padding:"0.6rem", height:"100%"}}>
              <RoundedContainer pressable={false} style={{ flex: "1", backgroundColor:"gray", padding:"1rem", display:"flex"}}>
              <ColumnContainer style={{flex:"auto"}}>
              <RowContainer style={{flexDirection:"row-reverse"}}>
              <CircleButton
              onClick={() => {
  
                generatePDFAndPrint(ordersMgmtSelectedData);
      
              }}
            >
              <PrintIcon sx={{ color: "black" }} />
            </CircleButton>
         
              </RowContainer>
              <Space style={{height:"1rem"}}/>
              <ColumnContainer style={{flex:"1"}}>
                <ScrollContainer
                  style={{ flex: "auto", display: "flex", flexDirection:"column", gap: "1rem" }}
                >
                  {ordersMgmtSelectedData?.models &&
                  ordersMgmtSelectedData.models.length > 0 ? (
                    ordersMgmtSelectedData.models.map((el, index) => {
                      const customerName =
                        el.customer?.name || UnknowenData.unknowenCustomer;
                      const carLicensePlate =
                        el.car?.kfz_kennzeichen || UnknowenData.unknowenPlates;

                      // Handle potential undefined or empty 'repairs' array
                      const repairs = el.repairs || [];

                      return (
                        <RoundedContainer
                          key={index}
               
                          onClick={() => {
                            setOrdersMgmtSelectedData((prevFormData) => ({
                              ...prevFormData,
                              models:
                                prevFormData.models?.filter(
                                  (model) => model !== el
                                ) || [], // Remove `el` and ensure models array remains defined
                            }));
                          }}
                        >
                          <ColumnContainer>
                            <LabelL>{customerName}</LabelL>
                            <LabelL>{carLicensePlate}</LabelL>

                            {/* Only render repairs if they exist */}
                            {repairs.length > 0 ? (
                              repairs.map((repair, idx) => (
                                <LabelL key={idx}>{repair}</LabelL>
                              ))
                            ) : (
                              <LabelL>{ordersMgmtData.noRepairs}</LabelL>
                            )}

                            <RowContainer
                              style={{
                                justifyContent: "flex-end",
                                opacity: "0.3",
                              }}
                            />
                          </ColumnContainer>
                        </RoundedContainer>
                      );
                    })
                  ) : (
                    <LabelL color="white" fontWeight="600">{ordersMgmtData.noRepairs}</LabelL>
                  )}
                </ScrollContainer>
                </ColumnContainer>
                </ColumnContainer>
              </RoundedContainer></ColumnContainer>
            </ColumnContainer>
          </RowContainer>
        </ColumnContainer>
      </SafePanel>
    </Panel>
  );
};

export default OrdersMgmt;
